import { api } from '../utils';

// ------------------------------------
// Constants
// ------------------------------------

const CHANGE_PASS_INPUT = 'changePassInput';
const CONFIRM_PASS_CHECK = 'confirmPassCheck';
const PASSWORD_MENU_SHOW = 'passwordMenuShow';
const PASSWORD_DATA_CLEAR = 'passwordDataClear';
const PASSWORD_UPDATE_FAIL = 'passwordUpdateFail';

const SEARCHING_CITY = 'searchingCity';
const RECEIVE_CITIES = 'receiveCities';
const CLEAR_CITIES_LIST = 'clearCitiesList';

const SET_AJAX_ERROR = 'setAjaxError';
const CLEAR_AJAX_ERROR = 'clearAjaxError';

// ------------------------------------
// Actions
// ------------------------------------

export const searchCity = (queryString) => {
    return function(dispatch) {
        dispatch(searchingCity());
        return api('/v1/location/cities?search=' + queryString + '&offset=0&limit=5', {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveCities(res)));
    };
};

function searchingCity() {
    return {
        type: SEARCHING_CITY
    };
}

function receiveCities(res) {
    return {
        type: RECEIVE_CITIES,
        payload: res
    };
}

export const clearCitiesList = () => {
    return {
        type: CLEAR_CITIES_LIST
    };
};

export const setAjaxError = (response) => {
    return {
        type: SET_AJAX_ERROR,
        payload: response
    };
};

export const clearAjaxError = (response) => {
    return {
        type: CLEAR_AJAX_ERROR,
        payload: response
    };
};

export const changePassInput = (e) => {
    return {
        type: CHANGE_PASS_INPUT,
        payload: {
            type: e.target.name,
            value: e.target.value
        }
    };
};

export const confirmPassCheck = (password) => {
    return {
        type: CONFIRM_PASS_CHECK,
        payload: password
    };
};

export const passUpdate = (password) => {
    return function(dispatch) {
        return api('/partner/password/change', {
            method: 'POST',
            body: JSON.stringify({
                password: password.currentPass,
                password_new: password.newPass
            })
        }, dispatch)
            .then(res => {
                if (res === false) {
                    dispatch(passwordUpdateFail());
                } else {
                    dispatch(passwordDataClear());
                }
            });
    };
};

function passwordUpdateFail(res) {
    return {
        type: PASSWORD_UPDATE_FAIL,
        payload: res
    };
}

export const passwordMenuShow = () => {
    return {
        type: PASSWORD_MENU_SHOW
    };
};

export const passwordDataClear = () => {
    return {
        type: PASSWORD_DATA_CLEAR
    };
};

export const actions = {
    changePassInput,
    confirmPassCheck,
    passUpdate,
    passwordMenuShow,
    clearAjaxError,
    searchCity,
    clearCitiesList
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_PASS_INPUT]: (state, action) => {
        const password = Object.assign({}, state.password);
        let status = state.checkPassStatus;
        password[action.payload.type] = action.payload.value;
        if (action.payload.value && action.payload.type === 'confirmPass' && password.newPass === action.payload.value) {
            status = 'success';
        } else if (action.payload.value && action.payload.type === 'newPass' && password.confirmPass === action.payload.value) {
            status = 'success';
        } else if (action.payload.type !== 'currentPass') {
            status = null;
        }

        return ({ ...state, showErrors: false, password: password, checkPassStatus: status });
    },
    [CONFIRM_PASS_CHECK]: (state, action) => {
        let status = null;
        if (action.payload.confirmPass) {
            if (action.payload.newPass === action.payload.confirmPass) {
                status = 'success';
            } else {
                status = 'fail';
            }
        }

        return ({ ...state, checkPassStatus: status });
    },
    [PASSWORD_MENU_SHOW]: (state) => {
        return ({ ...state, passwordMenu: !state.passwordMenu });
    },
    [PASSWORD_DATA_CLEAR]: (state) => {
        return ({ ...state, passwordMenu: false, password: { currentPass: '', confirmPass: '', newPass: '' }, checkPassStatus: null });
    },
    [PASSWORD_UPDATE_FAIL]: (state) => {
        return ({ ...state, showErrors: true });
    },
    [SET_AJAX_ERROR]: (state, action) => {
        return ({ ...state, ajaxError: action.payload });
    },
    [CLEAR_AJAX_ERROR]: (state) => {
        return ({ ...state, ajaxError: null });
    },
    [SEARCHING_CITY]: (state) => {
        return ({ ...state, fetchingCities: true });
    },
    [RECEIVE_CITIES]: (state, action) => {
        return ({ ...state, fetchingCities: false, cities: action.payload.cities });
    },
    [CLEAR_CITIES_LIST]: (state) => {
        return ({ ...state, cities: null });
    }
};

const initialState = {
    cities: [],
    statistics: null,
    password: {
        currentPass: '',
        confirmPass: '',
        newPass: ''
    },
    checkPassStatus: null,
    passwordMenu: false,
    showErrors: false,
    error: null,
    ajaxError: null
};

export default function hangarReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
