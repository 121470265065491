import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import PreLoader from '../../components/PreLoader/PreLoader';

const ReferencesLayout = ({children, referenceGroups, fetching, fetchingBusinessTypes, creating, updating, fetchingGroups, fetchReferenceGroups}) => {
    useEffect(() => {
        if (referenceGroups == null) {
            fetchReferenceGroups();
        }
    }, [referenceGroups, fetchReferenceGroups]);

    return (
        <div>
            {children}
            <PreLoader trigger={fetching || fetchingBusinessTypes || fetchingGroups || creating || updating} />
        </div>
    );
}


ReferencesLayout.propTypes = {
    fetching: PropTypes.bool,
    children: PropTypes.array
};

export default ReferencesLayout;
