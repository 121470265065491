import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../routes/Clients/components/ClientOrderReserve/clientOrderReserve.module.scss';
import { NavLink } from 'react-router-dom';
import CornerInfo from '../../routes/Clients/components/CornerInfo/CornerInfo';
import NavBar from '../../routes/Clients/components/NavBar/NavBar';

class ClientLayout extends React.Component {
    componentDidMount() {
        this.props.fetchClient(this.props.match.params.id);
    }

    render() {
        if (this.props.client === null) {
            return null;
        }

        return (
            <>
                <div className={classes.headerContainer}>
                    <div className={classes.labelDiv}>
                        <div className={classes.leftDiv}>
                            <div className={classes.mainLabel}>
                                <NavLink activeClassName={classes.active} className={classes.inactive} to='/clients/'>Список клиентов</NavLink>
                            </div>
                            <div className={classes.arrowRight} />
                            <div className={classes.clientLabel}>{ this.props.client.nick }</div>
                        </div>
                    </div>
                    <CornerInfo {...this.props} />
                </div>
                <NavBar client={this.props.client} />
                <div className={classes.containerPad}>
                    { this.props.children }
                </div>
            </>
        );
    }
}

ClientLayout.propTypes = {
    params: PropTypes.object,
    children: PropTypes.node,
    fetchClient: PropTypes.func,
    match: PropTypes.object,
    client: PropTypes.object
};

export default ClientLayout;
