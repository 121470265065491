import { api } from '../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
const CANCEL_FETCHING = 'cancelFetching';
const RECEIVE_PROLONGATIONS_SETTINGS = 'receiveProlongationsSettings';
const REQUEST_PROLONGATIONS_SETTINGS = 'requestProlongationsSettings';


// ------------------------------------
// Actions
// ------------------------------------

export const getProlongationCommonSettings = (businessTypeId) => (dispatch) => {
    businessTypeId && dispatch(requestProlongationSettings());
    return businessTypeId && api('/v4/admin/prolongations/settings/business-type/'+ businessTypeId, {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receivedProlongationSettings(res));
                return true;
            } else {
                dispatch(cancelFetching());
                return false;
            }
        });
};

export const updateProlongationCommonSettings = (businessTypeId, data) => (dispatch) => {
    businessTypeId &&  dispatch(requestProlongationSettings());
    return businessTypeId && api('/v4/admin/prolongations/settings/business-type/' + businessTypeId, {
        method: 'PUT',
        body: JSON.stringify({ settings: data })
    }, dispatch)
        .then(res => {
            if (res) {
                dispatch(receivedProlongationSettings(res));
                return true;
            } else {
                dispatch(cancelFetching());
                return false;
            }
        });
};

function receivedProlongationSettings(res) {
    return {
        type: RECEIVE_PROLONGATIONS_SETTINGS,
        payload: res
    };
}

function requestProlongationSettings(res) {
    return {
        type: REQUEST_PROLONGATIONS_SETTINGS,
        payload: res
    };
}

function cancelFetching(res) {
    return {
        type: CANCEL_FETCHING,
        payload: res
    };
}

export const actions = {
    getProlongationCommonSettings,
    updateProlongationCommonSettings
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_PROLONGATIONS_SETTINGS]: (state) => {

        return ({
            ...state,
            fetching: true,
        });
    },
    [CANCEL_FETCHING]: (state) => {

        return ({
            ...state,
            fetching: false,
        });
    },

    [RECEIVE_PROLONGATIONS_SETTINGS]: (state, action) => {
        return ({
            ...state,
            prolongationSettings: action.payload,
            fetching: false,
        });
    },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    prolongationSettings: null
};

export default function ProlongationsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
