export const phoneFormatToString = (number) => {
    if (typeof number !== 'string'
        || !number
        || number.length < 11
        || (number.replace(/(\D)/, '') && number.replace(/(\D)/, '')[0] !== '7')
    ) {
        return number;
    }
    return number.replace(/(\D)/, '')?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
};
