import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import communicationEvents from './modules/communicationEvents';

const LazyCommunicationEvents = React.lazy(() => import('./container'));

class CommunicationEvents extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('communicationEvents', communicationEvents);
        return (
            <Route exact path='/communication-events' render={props => <LazyCommunicationEvents />} />
        );
    }
}

export default CommunicationEvents;
