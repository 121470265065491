import { api, httpBuildQuery, isEmpty } from '../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_S_TEMPLATES = 'requestServiceTemplates';
const RECEIVE_S_TEMPLATES = 'receiveServiceTemplates';
const RECEIVE_S_TEMPLATES_BY_SEARCH = 'receiveServiceTemplatesBySearch';
const CREATE_SERVICE_TEMPLATE = 'createServiceTemplate';
const SERVICE_TEMPLATE_IS_CREATED = 'serviceTemplateIsCreated';
const SERVICE_TEMPLATE_IS_UPDATED = 'serviceTemplateIsUpdated';
const HIDE_SERVICE_TEMPLATE = 'hideServiceTemplate';
const SERVICE_TEMPLATE_IS_HIDDEN = 'serviceTemplateIsHidden';

const REQUEST_S_O_TEMPLATES = 'requestServiceOptionTemplates';
const RECEIVE_S_O_TEMPLATES = 'receiveServiceOptionTemplates';
const UPDATE_SERVICE_O_TEMPLATE = 'updateServiceOptionTemplate';
const SERVICE_O_TEMPLATE_IS_UPDATED = 'serviceOptionTemplateIsUpdated';
const CREATE_SERVICE_O_TEMPLATE = 'createServiceOptionTemplate';
const DELETE_SERVICE_O_TEMPLATE = 'createServiceOptionTemplate';
const SERVICE_O_TEMPLATE_IS_CREATED = 'serviceOptionTemplateIsCreated';
const SERVICE_O_TEMPLATE_IS_DELETED = 'serviceOptionTemplateIsDeleted';

const REQUEST_S_T_GROUPS = 'requestServiceTemplateGroups';
const RECEIVE_S_T_GROUPS = 'receiveServiceTemplateGroups';
const RECEIVE_S_T_GROUPS_BY_IDS = 'receiveServiceTemplateGroupsByIds';

const UPDATE_S_T_GROUP = 'updateServiceTemplateGroup';
const S_T_GROUP_IS_UPDATED = 'serviceTemplateGroupIsUpdated';

const CREATE_S_T_GROUP = 'createServiceTemplateGroup';
const S_T_GROUP_IS_CREATED = 'serviceTemplateGroupIsCreated';

const REQUEST_CATALOGUES = 'requestCatalogues';
const RECEIVE_CATALOGUES = 'receiveCatalogues';
const RECEIVE_CATALOGUES_BY_IDS = 'receiveCataloguesByIds';
const RECEIVE_CATALOGUES_BY_SEARCH = 'receiveCataloguesBySearch';

const UPDATE_CATALOGUE = 'updateCatalogue';
const CATALOGUE_IS_UPDATED = 'catalogueIsUpdated';
const CATALOGUE_IS_ROOTED = 'catalogueIsRooted';

const CREATE_CATALOGUE = 'createCatalogue';
const CATALOGUE_IS_CREATED = 'catalogueIsCreated';

const DELETE_CATALOGUE = 'deleteCatalogue';
const CATALOGUE_IS_DELETED = 'catalogueIsDeleted';

const CREATE_SERVICE_O_REFERENCE = 'createServiceOptionReference';
const DELETE_SERVICE_O_REFERENCE = 'deleteServiceOptionReference';
const SERVICE_O_REFERENCE_IS_CREATED = 'serviceOptionReferenceIsCreated';
const SERVICE_O_REFERENCE_IS_UPDATED = 'serviceOptionReferenceIsUpdated';
const SERVICE_O_REFERENCE_IS_DELETED = 'serviceOptionReferenceIsDeleted';
const REQUEST_REFERENCES = 'requestReferences';
const RECEIVE_REFERENCES = 'receiveReferences';

const ALL_CATALOGUES = 'allCatalogs';

// ------------------------------------
// Actions
// ------------------------------------

export const createServiceTemplate = (data, oldParent) => {
    return function(dispatch) {
        dispatch({ type: CREATE_SERVICE_TEMPLATE });
        return api('/v3/admin/services/templates?expand=image,thumbnails,serviceOptionTemplates', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: SERVICE_TEMPLATE_IS_CREATED, payload: response, oldParent: oldParent, data: data }));
    };
};

export const updateServiceTemplate = (data, oldParentId, query) => {
    return function(dispatch) {
        dispatch({ type: CREATE_SERVICE_TEMPLATE });
        return api('/v1/admin/service-templates/' + data.id + (_.isEmpty(query) ? '' : ('?' + httpBuildQuery(query))), {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => {
                dispatch({ type: SERVICE_TEMPLATE_IS_UPDATED, template: data, payload: response, oldParentId: oldParentId });
            });
    };
};

export const hideServiceTemplate = (id, keepResourced = 1) => {
    return function(dispatch) {
        dispatch({ type: HIDE_SERVICE_TEMPLATE });
        return api('/v1/admin/service-templates/' + id + '?keep_resourced?=' + keepResourced, {
            method: 'DELETE'
        }, dispatch)
            .then(response => {
                dispatch({ type: SERVICE_TEMPLATE_IS_HIDDEN, payload: response });
            });
    };
};

export const fetchServiceTemplates = (data) => {
    return function(dispatch) {
        dispatch(requestServiceTemplates());
        return api('/v1/admin/service-templates' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveServiceTemplates(response)));
    };
};

export const fetchServiceTemplatesBySearch = (search, limit) => {
    return function(dispatch) {
        dispatch(requestServiceTemplates());
        return api('/v1/admin/service-templates' + (_.isEmpty(search) ? '' : ('?' + httpBuildQuery({
            show_parent_nodes: false,
            search: search,
            limit: limit
        }))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_S_TEMPLATES_BY_SEARCH, payload: response }));
    };
};

export const requestServiceTemplates = () => {
    return {
        type: REQUEST_S_TEMPLATES
    };
};

function receiveServiceTemplates(response) {
    return {
        type: RECEIVE_S_TEMPLATES,
        payload: response
    };
}

export const fetchServiceOptionTemplates = (data) => {
    return function(dispatch) {
        dispatch(requestServiceOptionTemplates());
        return api('/v1/admin/service-option-templates' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveServiceOptionTemplates(response)));
    };
};

function requestServiceOptionTemplates() {
    return {
        type: REQUEST_S_O_TEMPLATES
    };
}

function receiveServiceOptionTemplates(response) {
    return {
        type: RECEIVE_S_O_TEMPLATES,
        payload: response
    };
}

export const createServiceOptionTemplate = (template) => {
    return function(dispatch) {
        dispatch({ type: UPDATE_SERVICE_O_TEMPLATE });
        return api('/v1/admin/service-option-templates', {
            method: 'POST',
            body: JSON.stringify(template)
        }, dispatch)
            .then(response => {
                dispatch({ type: SERVICE_O_TEMPLATE_IS_CREATED, payload: response });
            });
    };
};

export const updateServiceOptionTemplate = (template) => {
    return function(dispatch) {
        dispatch({ type: UPDATE_SERVICE_O_TEMPLATE });
        return api('/v1/admin/service-option-templates/' + template.id, {
            method: 'PUT',
            body: JSON.stringify(template)
        }, dispatch)
            .then(() => {
                dispatch({ type: SERVICE_O_TEMPLATE_IS_UPDATED });
            });
    };
};

export const deleteServiceOptionTemplate = (templateId) => {
    return function(dispatch) {
        dispatch({ type: DELETE_SERVICE_O_TEMPLATE });
        return api('/v3/admin/services/options/templates/' + templateId, {
            method: 'DELETE'
        }, dispatch)
            .then(() => {
                dispatch({ type: SERVICE_O_TEMPLATE_IS_DELETED, payload: templateId });
            });
    };
};

export const fetchCatalogues = (data) => {
    data.show_parent_nodes = false;
    if (!('is_hidden' in data)) {
        data.is_hidden = 'all';
    }
    return function(dispatch) {
        dispatch({ type: REQUEST_CATALOGUES });
        return api('/v3/admin/catalogues' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_CATALOGUES, payload: response }));
    };
};

export const fetchAllCataloguesFoType = (data) => (dispatch) => {
    const fetchData = {
        business_type_id: data.businessTypeId,
        root_only: data.rootOnly || 0,
        resourced_services_only: data?.resourcedServicesOnly || 0,
        show_parent_nodes: data?.showParentNodes || 1
    };
    dispatch({ type: REQUEST_CATALOGUES });
    return fetchCataloguesV4(fetchData)(dispatch);
};

export const fetchCataloguesV4 = (data, forDispatch = requestCompanyV4) => (dispatch) => {
    return api('/v4/admin/catalogues' + (isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
        method: 'GET'
    }, dispatch)
        .then(response => {
            dispatch(forDispatch(response))
            return response;
        })
        .catch(e => {
            return e;
        });
};

export const requestCompanyV4 = (response) => {
    return {
        type: ALL_CATALOGUES,
        payload: response
    };
};

export const fetchCataloguesByIds = (ids) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_CATALOGUES });
        return api('/v1/admin/catalogues' + (_.isEmpty(ids) ? '' : ('?' + httpBuildQuery({
            is_hidden: 'all',
            show_parent_nodes: false,
            expand: 'serviceTemplates',
            ids: ids,
            root_only: false
        }))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_CATALOGUES_BY_IDS, payload: response }));
    };
};

export const fetchCataloguesBySearch = (search) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_CATALOGUES });
        return api('/v1/admin/catalogues' + (_.isEmpty(search) ? '' : ('?' + httpBuildQuery({
            show_parent_nodes: false,
            search: search
        }))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_CATALOGUES_BY_SEARCH, payload: response }));
    };
};

export const updateCatalogue = (data, oldParentId, query) => {
    return function(dispatch) {
        dispatch({ type: UPDATE_CATALOGUE });
        return api('/v1/admin/catalogues/' + data.id + (_.isEmpty(query) ? '' : ('?' + httpBuildQuery(query))), {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: CATALOGUE_IS_UPDATED, payload: response, oldParentId: oldParentId, data: data }));
    };
};

export const makeCatalogueRoot = (data, oldParentId, query) => {
    return function(dispatch) {
        dispatch({ type: UPDATE_CATALOGUE });
        return api('/v1/admin/catalogues/' + data.id + (_.isEmpty(query) ? '' : ('?' + httpBuildQuery(query))), {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: CATALOGUE_IS_ROOTED, catalogue: data, payload: response, oldParentId: oldParentId }));
    };
};

export const createCatalogue = (data) => {
    return function(dispatch) {
        dispatch({ type: CREATE_CATALOGUE });
        return api('/v1/admin/catalogues', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: CATALOGUE_IS_CREATED, payload: response }));
    };
};

export const deleteCatalogue = (data) => {
    return function(dispatch) {
        dispatch({ type: DELETE_CATALOGUE });
        return api(`/v3/admin/catalogues/${data.id}`, {
            method: 'DELETE'
        }, dispatch)
            .then(response => dispatch({ type: CATALOGUE_IS_DELETED, payload: response }));
    };
};

export const fetchServiceTemplateGroups = (data) => {
    data.show_parent_nodes = false;
    data.is_hidden = 'all';
    return function(dispatch) {
        dispatch({ type: REQUEST_S_T_GROUPS });
        return api('/v1/admin/service-template-groups' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_S_T_GROUPS, payload: response }));
    };
};

export const fetchServiceTemplateGroupsByIds = (ids) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_S_T_GROUPS });
        return api('/v1/admin/service-template-groups' + (_.isEmpty(ids) ? '' : ('?' + httpBuildQuery({
            show_parent_nodes: false,
            is_hidden: 'all',
            expand: 'serviceTemplates',
            ids: ids,
            root_only: false
        }))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_S_T_GROUPS_BY_IDS, payload: response }));
    };
};

export const updateServiceTemplateGroup = (data, query) => {
    return function(dispatch) {
        dispatch({ type: UPDATE_S_T_GROUP });
        return api('/v1/admin/service-template-groups/' + data.id + (_.isEmpty(query) ? '' : ('?' + httpBuildQuery(query))), {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: S_T_GROUP_IS_UPDATED, serviceTemplateGroup: data, payload: response }));
    };
};

export const createServiceTemplateGroup = (data) => {
    return function(dispatch) {
        dispatch({ type: CREATE_S_T_GROUP });
        return api('/v1/admin/service-template-groups/', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: S_T_GROUP_IS_CREATED, payload: response }));
    };
};

export const fetchServiceOptionReferences = () => {
    return function(dispatch) {
        dispatch({ type: REQUEST_REFERENCES });
        return api('/v1/admin/references', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_REFERENCES, payload: response }));
    };
};

export const updateServiceOptionReference = (reference) => {
    return function(dispatch) {
        dispatch({ type: CREATE_SERVICE_O_REFERENCE });
        return api('/v1/admin/references/' + reference.id + '?expand=values', {
            method: 'PUT',
            body: JSON.stringify(reference)
        }, dispatch)
            .then(() => {
                dispatch({ type: SERVICE_O_REFERENCE_IS_UPDATED, payload: reference });
            });
    };
};

export const createServiceOptionReference = (reference) => {
    return function(dispatch) {
        dispatch({ type: CREATE_SERVICE_O_REFERENCE });
        return api('/v1/admin/references', {
            method: 'POST',
            body: JSON.stringify(reference)
        }, dispatch)
            .then(response =>
                dispatch({ type: SERVICE_O_REFERENCE_IS_CREATED, payload: response, data: reference }));
    };
};

export const deleteServiceOptionReference = (reference) => {
    return function(dispatch) {
        dispatch({ type: DELETE_SERVICE_O_REFERENCE });
        return api(`/v4/admin/reference/${reference}`, {
            method: 'DELETE'
        }, dispatch)
            .then(response =>
                dispatch({ type: SERVICE_O_REFERENCE_IS_DELETED, payload: response }));
    };
};

export const actions = {
    fetchServiceOptionTemplates,
    updateServiceOptionTemplate,
    createServiceOptionTemplate,
    deleteServiceOptionTemplate,
    fetchServiceTemplates,
    fetchServiceTemplatesBySearch,
    updateServiceTemplate,
    createServiceTemplate,
    hideServiceTemplate,
    fetchServiceTemplateGroups,
    fetchServiceTemplateGroupsByIds,
    updateServiceTemplateGroup,
    createServiceTemplateGroup,
    fetchCatalogues,
    fetchCataloguesByIds,
    updateCatalogue,
    createCatalogue,
    updateServiceOptionReference,
    createServiceOptionReference,
    deleteServiceOptionReference,
    fetchServiceOptionReferences,
    deleteCatalogue,
    makeCatalogueRoot
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_S_O_TEMPLATES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplates = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_S_O_TEMPLATES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplates = false;
        return ({ ...state, fetching: fetching, serviceOptionTemplates: action.payload });
    },
    [REQUEST_S_TEMPLATES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplates = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_S_TEMPLATES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplates = false;
        let serviceTemplates = state.serviceTemplates;
        if (action.payload && action.payload.items) {
            action.payload.items = _.sortBy(action.payload.items, 'sort');
            serviceTemplates = action.payload;
        }
        return ({ ...state, fetching: fetching, serviceTemplates: serviceTemplates });
    },
    [RECEIVE_S_TEMPLATES_BY_SEARCH]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplates = false;
        return ({ ...state, fetching: fetching, serviceTemplatesBySearch: action.payload });
    },
    [CREATE_SERVICE_TEMPLATE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplate = true;
        return ({ ...state, fetching: fetching });
    },
    [SERVICE_TEMPLATE_IS_CREATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplate = false;
        const serviceTemplates = _.cloneDeep(state.serviceTemplates);
        const catalogues = _.cloneDeep(state.catalogues);
        if (action.payload.message) {
            alert(action.payload.message);
            return ({ ...state, catalogues: catalogues, serviceTemplates: serviceTemplates, fetching: fetching });
        } else {
            const newTemplate = _.cloneDeep(action.payload);
            if (action.data.image) {
                newTemplate.image = action.data.image;
            }
            if (action.data.image2) {
                newTemplate.image2 = action.data.image2;
            }
            serviceTemplates.items.push(newTemplate);
            const keyCat = action.payload.catalogue ? _.findIndex(catalogues.items, ['id', action.payload.catalogue.id]) : -1;
            if (keyCat !== -1) {
                if (catalogues.items[keyCat].serviceTemplates) {
                    catalogues.items[keyCat].serviceTemplates.push(newTemplate);
                    catalogues.items[keyCat].serviceTemplates = _.sortBy(catalogues.items[keyCat].serviceTemplates, 'sort');
                } else {
                    catalogues.items[keyCat].serviceTemplates = [];
                    catalogues.items[keyCat].serviceTemplates.push(newTemplate);
                    catalogues.items[keyCat].serviceTemplates = _.sortBy(catalogues.items[keyCat].serviceTemplates, 'sort');
                }
            }
            return ({ ...state, serviceTemplates: serviceTemplates, catalogues: catalogues, fetching: fetching, newTemplate: newTemplate });
        }
    },
    [REQUEST_REFERENCES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.references = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_REFERENCES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.references = false;
        return ({ ...state, fetching: fetching, references: action.payload });
    },
    [CREATE_SERVICE_O_REFERENCE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.references = true;
        fetching.referenceCreate = true;
        return ({ ...state, fetching: fetching });
    },
    [SERVICE_O_REFERENCE_IS_CREATED]: (state, action) => {
        const newReference = _.cloneDeep(action.payload);
        newReference.values = action.data.values;
        const fetching = Object.assign({}, state.fetching);
        fetching.references = false;
        fetching.referenceCreate = false;
        const references = _.cloneDeep(state.references);
        references.items.push(newReference);
        return ({ ...state, fetching: fetching, references: references });
    },
    [SERVICE_O_REFERENCE_IS_DELETED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.references = false;
        fetching.referenceDelete = false;
        const references = _.cloneDeep(state.references);
        _.remove(references.items, action.payload);
        return ({ ...state, fetching: fetching, references: references });
    },
    [DELETE_SERVICE_O_REFERENCE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.references = true;
        fetching.referenceCreate = true;
        return ({ ...state, fetching: fetching });
    },
    [SERVICE_O_REFERENCE_IS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.references = false;
        const references = _.cloneDeep(state.references);
        const key = _.findIndex(_.get(references, 'items', []), { id: action.id });
        if (key !== -1) {
            references.items[key] = action.payload;
        }
        return ({ ...state, fetching: fetching, references: references });
    },
    [REQUEST_S_T_GROUPS]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplateGroups = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_S_T_GROUPS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplateGroups = false;
        let serviceTemplateGroups = state.serviceTemplateGroups;
        if (action.payload && action.payload.items) {
            action.payload.items = _.sortBy(action.payload.items, 'sort');
            serviceTemplateGroups = action.payload;
        }
        return ({ ...state, fetching: fetching, serviceTemplateGroups: serviceTemplateGroups });
    },
    [RECEIVE_S_T_GROUPS_BY_IDS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplateGroups = false;
        const serviceTemplateGroups = Object.assign([], state.serviceTemplateGroupsByIds);
        _.each(action.payload.items, item => {
            const index = _.findIndex(serviceTemplateGroups, { id: item.id });
            if (index !== -1) {
                serviceTemplateGroups[index] = item;
            } else {
                serviceTemplateGroups.push(item);
            }
        });
        return ({ ...state, fetching: fetching, serviceTemplateGroupsByIds: serviceTemplateGroups });
    },
    [UPDATE_S_T_GROUP]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.updateServiceTemplateGroups = true;
        return ({ ...state, fetching: fetching });
    },
    [S_T_GROUP_IS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.updateServiceTemplateGroups = false;

        const serviceTemplateGroups = _.clone(state.serviceTemplateGroups);
        const key = _.findKey(_.get(serviceTemplateGroups, 'items', []), { id: action.serviceTemplateGroup.id });
        if (key) {
            serviceTemplateGroups.items[key] = action.payload;
        }
        return ({ ...state, fetching: fetching, serviceTemplateGroups: serviceTemplateGroups });
    },
    [CREATE_S_T_GROUP]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplateGroup = true;
        return ({ ...state, fetching: fetching });
    },
    [S_T_GROUP_IS_CREATED]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplateGroup = false;
        return ({ ...state, fetching: fetching });
    },
    [REQUEST_CATALOGUES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_CATALOGUES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        let catalogues = Object.assign({}, state.catalogues);
        fetching.catalogues = false;
        if (action.payload && action.payload.items) {
            action.payload.items = _.sortBy(action.payload.items, 'sort');
            catalogues = action.payload;
        }
        return ({ ...state, fetching: fetching, catalogues: catalogues });
    },
    [ALL_CATALOGUES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        let catalogues = [];
        fetching.catalogues = false;
        if (action.payload && action.payload.items) {
            action.payload.items = _.sortBy(action.payload.items, 'sort');
            action.payload.total = action.payload?.items?.length;
            catalogues = action.payload;
        }
        return ({ ...state, fetching: fetching, cataloguesFlatForType: catalogues });
    },
    [RECEIVE_CATALOGUES_BY_SEARCH]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        return ({ ...state, fetching: fetching, cataloguesBySeach: action.payload });
    },
    [UPDATE_CATALOGUE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = true;
        return ({ ...state, fetching: fetching });
    },
    [CATALOGUE_IS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        const catalogues = _.cloneDeep(state.catalogues);
        const fullCatalogue = _.find(catalogues.items, ['id', action.payload.id]);
        fullCatalogue.parentCatalogue = action.payload.parentCatalogue;
        fullCatalogue.sort = action.payload.sort;
        fullCatalogue.isHidden = action.payload.isHidden;
        fullCatalogue.image = action.data.image;
        fullCatalogue.image2 = action.data.image2;
        fullCatalogue.name = action.data.name;
        if (action.payload.message) {
            alert(action.payload.message);
            return ({ ...state, catalogues: catalogues, fetching: fetching });
        } else {
            const keyCatalogue = _.findIndex(_.get(catalogues, 'items', []), { id: action.payload.id });
            const oldParentCat = _.findIndex(_.get(catalogues, 'items', []), { id: action.oldParentId });
            let parentCatalogue;
            if (action.payload.parentCatalogue) {
                parentCatalogue = _.findIndex(_.get(catalogues, 'items', []), { id: action.payload.parentCatalogue.id });
                catalogues.items[keyCatalogue] = fullCatalogue;
                if (oldParentCat !== -1 && catalogues.items[oldParentCat].childrenCatalogues) {
                    _.remove(catalogues.items[oldParentCat].childrenCatalogues, ['id', action.payload.id]);
                    catalogues.items[parentCatalogue].childrenCatalogues.push(fullCatalogue);
                } else {
                    _.remove(catalogues.items[parentCatalogue].childrenCatalogues, ['id', action.payload.id]);
                    catalogues.items[parentCatalogue].childrenCatalogues.push(fullCatalogue);
                    catalogues.items[parentCatalogue].childrenCatalogues = _.sortBy(catalogues.items[parentCatalogue].childrenCatalogues, 'sort');
                }
            }
            if (action.oldParentId && action.payload.parentCatalogue) {
                _.remove(catalogues.items[oldParentCat].childrenCatalogues, ['id', action.payload.id]);
            }
            if (!action.oldParentId && action.payload.parentCatalogue) {
                catalogues.items[keyCatalogue].parentCatalogue = { id: action.payload.parentCatalogue.id };
                catalogues.items[parentCatalogue].childrenCatalogues = _.sortBy(catalogues.items[parentCatalogue].childrenCatalogues, 'sort');
            }
            // catalogues.items[parentCatalogue].childrenCatalogues = _.sortBy(catalogues.items[parentCatalogue].childrenCatalogues, 'sort');
            catalogues.items[keyCatalogue] = fullCatalogue;
            catalogues.items = _.sortBy(catalogues.items, 'sort');
            return ({ ...state, fetching: fetching, catalogues: catalogues });
        }
    },
    [CATALOGUE_IS_ROOTED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        const catalogues = _.cloneDeep(state.catalogues);
        const fullCatalogue = _.find(catalogues.items, ['id', action.payload.id]);
        if (action.payload.message) {
            alert(action.payload.message);
            return ({ ...state, catalogues: catalogues, fetching: fetching });
        } else {
            fullCatalogue.sort = action.payload.sort;
            const keyCatalogue = _.findIndex(_.get(catalogues, 'items', []), { id: action.payload.id });
            const oldParentCat = _.findIndex(_.get(catalogues, 'items', []), { id: action.oldParentId });
            _.remove(catalogues.items[oldParentCat].childrenCatalogues, ['id', action.payload.id]); catalogues.items[keyCatalogue].parentCatalogue = null;
            catalogues.items = _.sortBy(catalogues.items, 'sort');
            return ({ ...state, fetching: fetching, catalogues: catalogues });
        }
    },
    [CREATE_CATALOGUE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = true;
        return ({ ...state, fetching: fetching });
    },
    [CATALOGUE_IS_CREATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        const catalogues = _.cloneDeep(state.catalogues);
        if (action.payload.message) {
            alert(action.payload.message);
            return ({ ...state, catalogues: catalogues, fetching: fetching });
        } else {
            let parentCatalogue;
            if (action.payload.parentCatalogue) {
                parentCatalogue = _.findIndex(catalogues.items, { id: action.payload.parentCatalogue.id });
                if (!_.isEmpty(catalogues.items[parentCatalogue].childrenCatalogues)) {
                    catalogues.items[parentCatalogue].childrenCatalogues.push(action.payload);
                    catalogues.items.push(action.payload);
                    catalogues.items[parentCatalogue].childrenCatalogues = _.sortBy(catalogues.items[parentCatalogue].childrenCatalogues, 'sort');
                } else {
                    catalogues.items[parentCatalogue].childrenCatalogues = [];
                    catalogues.items[parentCatalogue].childrenCatalogues.push(action.payload);
                    catalogues.items.push(action.payload);
                    catalogues.items[parentCatalogue].childrenCatalogues = _.sortBy(catalogues.items[parentCatalogue].childrenCatalogues, 'sort');
                }
            } else {
                catalogues.items.push(action.payload);
            }
            catalogues.items = _.sortBy(catalogues.items, 'sort');
            return ({ ...state, catalogues: catalogues, fetching: fetching });
        }
    },
    [DELETE_CATALOGUE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = true;
        return ({ ...state, fetching: fetching });
    },
    [CATALOGUE_IS_DELETED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        const catalogues = _.cloneDeep(state.catalogues);
        const keyCatalogue = _.findIndex(_.get(catalogues, 'items', []), { id: action.payload.id });
        if (action.payload.message) {
            alert(action.payload.message);
            return ({ ...state, catalogues: catalogues, fetching: fetching });
        } else {
            fetching.catalogues = false;
            if (action.payload.parentCatalogue) {
                const parentCat = _.findIndex(_.get(catalogues, 'items', []), { id: action.payload.parentCatalogue.id });
                _.remove(catalogues.items[parentCat].childrenCatalogues, ['id', action.payload.id]);
                catalogues.items[parentCat].childrenCatalogues.push(action.payload);
            } else {
                const index = _.findIndex(catalogues.items, { id: action.payload.id });
                catalogues.items.splice(index, 1);
                catalogues.items.push(action.payload);
            }
            catalogues.items[keyCatalogue] = action.payload;
            catalogues.items = _.sortBy(catalogues.items, 'sort');
            return ({ ...state, catalogues: catalogues, fetching: fetching });
        }
    },
    [RECEIVE_CATALOGUES_BY_IDS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.catalogues = false;
        const catalogues = Object.assign([], state.cataloguesByIds);
        _.each(action.payload.items, item => {
            const index = _.findIndex(catalogues, { id: item.id });
            if (index !== -1) {
                catalogues[index] = item;
            } else {
                catalogues.push(item);
            }
        });
        return ({ ...state, fetching: fetching, cataloguesByIds: catalogues });
    },
    [SERVICE_TEMPLATE_IS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        const serviceTemplates = _.cloneDeep(state.serviceTemplates);
        const catalogues = _.cloneDeep(state.catalogues);
        fetching.createServiceTemplate = false;
        if (action.payload.message) {
            alert(action.payload.message);
            return ({ ...state, catalogues: catalogues, serviceTemplates: serviceTemplates, fetching: fetching });
        } else {
            const oldParentCat = _.findIndex(catalogues.items, ['id', action.oldParentId]);
            const parentCatalogue = _.findIndex(catalogues.items, ['id', action.template.catalogue.id]);
            const key = _.findIndex(_.get(serviceTemplates, 'items', []), { id: action.template.id });
            if (key !== -1) {
                serviceTemplates.items[key] = action.template;
                if (oldParentCat !== -1) {
                    _.remove(catalogues.items[oldParentCat].serviceTemplates, ['id', action.template.id]);
                    catalogues.items[parentCatalogue].serviceTemplates.push(action.template);
                    catalogues.items[parentCatalogue].serviceTemplates = _.sortBy(catalogues.items[parentCatalogue].serviceTemplates, 'sort');
                }
                catalogues.items[parentCatalogue].serviceTemplates = _.sortBy(catalogues.items[parentCatalogue].serviceTemplates, 'sort');
                catalogues.items = _.sortBy(catalogues.items, 'sort');
            }
            return ({ ...state, fetching: fetching, serviceTemplates: serviceTemplates, catalogues: catalogues });
        }
    },
    [CREATE_SERVICE_O_TEMPLATE]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplate = false;
        const serviceOptionTemplates = _.clone(state.serviceOptionTemplates);
        if (state.serviceOptionTemplates.items) {
            state.serviceOptionTemplates.items.push(action.template);
        }
        return ({ ...state, serviceOptionTemplates: serviceOptionTemplates, fetching: fetching });
    },
    [DELETE_SERVICE_O_TEMPLATE]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplate = false;
        const serviceOptionTemplates = _.clone(state.serviceOptionTemplates);
        if (state.serviceOptionTemplates.items) {
            state.serviceOptionTemplates.items.push(action.payload);
        }
        return ({ ...state, serviceOptionTemplates: serviceOptionTemplates, fetching: fetching });
    },
    [SERVICE_O_TEMPLATE_IS_CREATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplate = false;
        const serviceOptionTemplates = _.cloneDeep(state.serviceOptionTemplates);
        serviceOptionTemplates.items.push(action.payload);
        const references = _.cloneDeep(state.references);
        if (action.payload.serviceOptionReference) {
            const  reference = _.find(references.items, {id: action.payload.serviceOptionReference.id})
            if (reference) {
                reference.serviceOptionTemplates.push(action.payload);
            }
        }
        return ({ ...state, serviceOptionTemplates: serviceOptionTemplates, references: references, fetching: fetching });
    },
    [SERVICE_O_TEMPLATE_IS_DELETED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplate = false;
        const serviceOptionTemplates = _.cloneDeep(state.serviceOptionTemplates);
        const index = _.findIndex(serviceOptionTemplates.items, ['id', action.payload]);
        serviceOptionTemplates.items.splice(index, 1);
        return ({ ...state, fetching: fetching, serviceOptionTemplates: serviceOptionTemplates });
    },
    [UPDATE_SERVICE_O_TEMPLATE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplate = true;
        return ({ ...state, fetching: fetching });
    },
    [SERVICE_O_TEMPLATE_IS_UPDATED]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceOptionTemplate = false;
        return ({ ...state, fetching: fetching });
    }
};

const initialState = {
    fetching: {},
    serviceOptionTemplates: null,
    serviceTemplates: null,
    references: null,
    serviceTemplatesBySearch: null,
    serviceTemplateGroups: null,
    serviceTemplateGroupsByIds: null,
    catalogues: {},
    cataloguesByIds: [],
    newTemplate: null,
    cataloguesFlatForType: null
};

export default function cataloguesReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
