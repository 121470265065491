import { connect } from 'react-redux';
import ClientLayout from './ClientLayout';
import { actions } from '../../routes/Clients/modules/Clients.reducer';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    client: state.clients.client
});

export default withRouter(connect(mapStateToProps, actions)(ClientLayout));
