import { api } from './../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
const CHANGE_VALUE = 'changeValue';
const SET_USER = 'setUser';
const SET_ERROR = 'setError';

const CLEAR_AJAX_ERROR = 'clearAjaxError'; // module/hangar

// ------------------------------------
// Actions
// ------------------------------------

export const setUser = (tokenAndUser) => {
    return {
        type: SET_USER,
        payload: tokenAndUser
    };
};

export const submit = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().account;
        return api('/admin/login', {
            method: 'post',
            body: JSON.stringify({ phone: state.login.trim().replace(/([^0-9+]*)/ig, ''), password: state.password.trim() })

        }, dispatch)
            .then(res => {
                if (res !== false) {
                    dispatch(clearAjaxError());
                    dispatch((setUser(res)));
                } else {
                    dispatch(setError());
                }
            });
    };
};
export const clearAjaxError = () => {
    return {
        type: CLEAR_AJAX_ERROR
    };
};

export const setError = () => {
    return {
        type: SET_ERROR
    };
};

export const changeValue = (e) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const actions = {
    changeValue,
    setUser,
    setError,
    submit
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, error: false, [action.payload.name]: action.payload.value });
    },
    [SET_ERROR]: () => {
        // данный обработчик находится в module/auth
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    login: '',
    password: '',
    error: false
};

// eslint-disable-next-line default-param-last
export default function loginReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
