import { api, httpBuildQuery } from '../../../utils';
import _ from 'lodash';
// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_CLIENTS = 'clients:requestClients';
const RECEIVE_CLIENTS = 'clients:receiveClients';

// const REQUEST_CLIENTS_COMPANIES = 'requestClientsCompanies';

const RECEIVE_CLIENT = 'clients:receiveClient';

const REQUEST_CLIENT_RESERVES = 'clients:requestClientReserves';
const RECEIVE_CLIENT_RESERVES = 'clients:receiveClientReserves';

const REQUEST_CLIENT_ORDERS = 'requestClientOrders';
const RECEIVE_CLIENT_ORDERS = 'receiveClientOrders';

const RECEIVE_CLIENT_CARS = 'clients:receiveClientCars';

const RECEIVE_CLIENT_NOTIFICATIONS = 'clients:receiveClientNotifications';

const RECEIVE_CLIENT_OKK_NOTIFICATIONS = 'clients:receiveClientOkkNotifications';

const CHANGE_CLIENTS_SEARCH_VALUE = 'clients:changeClientsSearchValue';

const CLEAR_CLIENTS = 'clients:clearClients';

const CHANGE_ORDERS_IDS = 'clients:changeOrdersIds';
const CHANGE_ORDERS_DATE = 'clients:changeOrdersDate';

const CHANGE_RESERVES_IDS = 'clients:changeReservesIds';
const CHANGE_RESERVES_DATE = 'clients:changeReservesDate';

const CHANGE_CLIENT_COMPANY = 'clients:changeClientCompany';

const USER_UPDATING = 'clients:userUpdating';
const USER_UPDATED = 'clients:userUpdated';
const CHANGE_PAGER = 'clients:changePager';

const REQUEST_COMMUNICATION_EVENTS = 'clients:requestCommunicationEvents';
const RECEIVE_COMMUNICATION_EVENTS = 'clients:receiveCommunicationEvents';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCommunicationEvents = (data) => {
    return function(dispatch) {
        dispatch(requestCommunicationEvents());
        return api('/v3/admin/communication?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveCommunicationEvents(res));
                }
            });
    };
};

function requestCommunicationEvents() {
    return {
        type: REQUEST_COMMUNICATION_EVENTS
    };
}

function receiveCommunicationEvents(res, merge = false) {
    return {
        type: RECEIVE_COMMUNICATION_EVENTS,
        merge: merge,
        payload: res
    };
}

export const fetchClients = (data) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api('/v1/admin/clients?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveClients(res));
                }
            });
    };
};

export const searchClients = (data) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api('/v3/admin/clients/search?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveClients(res));
                }
            });
    };
};

export const searchClientsCompanies = (id, data) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api(`/v1/admin/clients/${id}search?=` + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveClients(res));
                }
            });
    };
};

/* function requestClientsCompanies() {
    return {
        type: REQUEST_CLIENTS_COMPANIES
    };
} */
function requestClients() {
    return {
        type: REQUEST_CLIENTS
    };
}

function receiveClients(res) {
    return {
        type: RECEIVE_CLIENTS,
        payload: res
    };
}

export const clearClients = () => {
    return {
        type: CLEAR_CLIENTS
    };
};

export const fetchClient = (id, data) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api('/v1/admin/clients/' + id + '?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.client) {
                    return dispatch(receiveClient(res.client));
                }
            });
    };
};

function receiveClient(res) {
    return {
        type: RECEIVE_CLIENT,
        payload: res
    };
}

export const fetchClientReserves = (data) => {
    return function(dispatch) {
        dispatch(requestClientReserves());
        return api('/v1/admin/clients/reserves?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.reserves) {
                    return dispatch(receiveClientReserves(res));
                }
            });
    };
};
function requestClientReserves() {
    return {
        type: REQUEST_CLIENT_RESERVES
    };
}
function receiveClientReserves(res) {
    return {
        type: RECEIVE_CLIENT_RESERVES,
        payload: res
    };
}

export const fetchClientOrders = (data) => {
    return function(dispatch) {
        dispatch(requestClientOrders());
        return api('/v4/admin/agenda?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receiveClientOrders(res));
                }
            });
    };
};

function requestClientOrders(res) {
    return {
        type: REQUEST_CLIENT_ORDERS,
        payload: res
    };
}
function receiveClientOrders(res) {
    return {
        type: RECEIVE_CLIENT_ORDERS,
        payload: res
    };
}

export const fetchClientCars = (id) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api('/v3/admin/clients/' + id + '/cars', {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveClientCars(res));
                }
            });
    };
};

function receiveClientCars(res) {
    return {
        type: RECEIVE_CLIENT_CARS,
        payload: res
    };
}

export const fetchClientNotifications = (id, data) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api('/v1/admin/clients/' + id + '/notifications?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.notifications) {
                    return dispatch(receiveClientNotifications(res));
                }
            });
    };
};

function receiveClientNotifications(res) {
    return {
        type: RECEIVE_CLIENT_NOTIFICATIONS,
        payload: res
    };
}

export const fetchClientOkkNotifications = (id, data) => {
    return function(dispatch) {
        dispatch(requestClients());
        return api('/v1/admin/clients/' + id + '/okk-notifications?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.okkNotifications) {
                    return dispatch(receiveClientOkkNotifications(res));
                }
            });
    };
};

function receiveClientOkkNotifications(res) {
    return {
        type: RECEIVE_CLIENT_OKK_NOTIFICATIONS,
        payload: res
    };
}

export const changeClientsSearchValue = (data) => {
    return {
        type: CHANGE_CLIENTS_SEARCH_VALUE,
        payload: data
    };
};

export const changeOrdersIds = (ids) => {
    return {
        type: CHANGE_ORDERS_IDS,
        payload: ids
    };
};

export const changeOrdersDate = (name, value) => {
    return {
        type: CHANGE_ORDERS_DATE,
        payload: {
            name,
            value
        }
    };
};

export const changeReservesIds = (ids) => {
    return {
        type: CHANGE_RESERVES_IDS,
        payload: ids
    };
};

export const changeReservesDate = (name, value) => {
    return {
        type: CHANGE_RESERVES_DATE,
        payload: {
            name,
            value
        }
    };
};

export const changeClientCompany = (company) => {
    return {
        type: CHANGE_CLIENT_COMPANY,
        payload: company
    };
};

export const actions = {
    fetchClients,
    searchClients,
    fetchClient,
    fetchClientReserves,
    fetchClientOrders,
    fetchClientCars,
    fetchClientNotifications,
    fetchClientOkkNotifications,
    changeClientsSearchValue,
    clearClients,
    changeOrdersIds,
    changeOrdersDate,
    changeReservesDate,
    changeReservesIds,
    changeClientCompany,
    fetchCommunicationEvents
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_COMMUNICATION_EVENTS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [REQUEST_CLIENT_ORDERS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_COMMUNICATION_EVENTS]: (state, action) => {
        let events;
        if (action.merge) {
            events = Object.assign({}, state.clients);
            _.each(action.payload.items, event => {
                const existsKey = _.findIndex(events.items, { id: event.id });
                if (existsKey !== -1) {
                    events.items[existsKey] = event;
                }
            });
        } else {
            events = action.payload;
        }
        return ({ ...state, events: events, fetching: false });
    },
    [REQUEST_CLIENTS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CLIENTS]: (state, action) => {
        return ({ ...state, clients: action.payload, fetching: false });
    },
    [RECEIVE_CLIENT]: (state, action) => {
        return ({ ...state, client: action.payload, fetching: false });
    },
    [REQUEST_CLIENT_RESERVES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CLIENT_RESERVES]: (state, action) => {
        return ({ ...state, clientReserves: action.payload, fetching: false });
    },
    [RECEIVE_CLIENT_ORDERS]: (state, action) => {
        return ({ ...state, clientOrders: action.payload, fetching: false });
    },
    [RECEIVE_CLIENT_CARS]: (state, action) => {
        return ({ ...state, cars: action.payload, fetching: false });
    },
    [RECEIVE_CLIENT_NOTIFICATIONS]: (state, action) => {
        return ({ ...state, clientNotifications: action.payload, fetching: false });
    },
    [RECEIVE_CLIENT_OKK_NOTIFICATIONS]: (state, action) => {
        return ({ ...state, clientOkkNotifications: action.payload, fetching: false });
    },
    [CHANGE_CLIENTS_SEARCH_VALUE]: (state, action) => {
        return ({ ...state, clientsSearchValue: action.payload });
    },
    [CLEAR_CLIENTS]: (state) => {
        return ({ ...state, clients: null });
    },
    [CHANGE_ORDERS_IDS]: (state, action) => {
        return ({ ...state, ordersIds: action.payload });
    },
    [CHANGE_ORDERS_DATE]: (state, action) => {
        return ({ ...state, [action.payload.name]: action.payload.value });
    },
    [CHANGE_RESERVES_IDS]: (state, action) => {
        return ({ ...state, reservesIds: action.payload });
    },
    [CHANGE_RESERVES_DATE]: (state, action) => {
        return ({ ...state, [action.payload.name]: action.payload.value });
    },
    [CHANGE_CLIENT_COMPANY]: (state, action) => {
        return ({ ...state, clientCompany: action.payload });
    },
    [USER_UPDATING]: (state) => {
        return ({ ...state, fetching: true });
    },
    [USER_UPDATED]: (state, action) => {
        return ({ ...state, user: action.payload.user, fetching: false });
    },
    [CHANGE_PAGER]: (state, action) => {
        return ({ ...state, ...action.payload });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    clients: null,
    client: null,
    clientReserves: null,
    clientOrders: null,
    clientNotifications: null,
    clientOkkNotifications: null,
    clientsSearchValue: '',
    ordersIds: '',
    ordersRangeStart: '',
    ordersRangeEnd: '',
    reservesIds: '',
    reservesRangeStart: '',
    reservesRangeEnd: '',
    clientCompany: null,
    orders: null,
    cars: null,
    limit: 20,
    offset: 0,
    events: null
};

export default function ClientsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
