import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// We only need to import the module necessary for initial render
/*
import CoreLayout from '../layouts/CoreLayout';
import Home from './Home';
import NotFound from './NotFound';
import StatisticGraph from './StatisticGraph';
import Users from './Users';
import CompanyOrders from './CompanyOrders';
import CommunicationEvents from './CommunicationEvents';
import References from './References';
import Services from './Services';
import ServiceTemplates from './ServiceTemplates/CompanyOrders';
import ServiceTemplateGroups from './ServiceTemplateGroups/CompanyOrders';
import ServiceTemplateGroupsItem from './ServiceTemplateGroups/Item';
import ServiceTemplateGroupsCreate from './ServiceTemplateGroups/Create';
import Catalogues from './Catalogues/CompanyOrders';
import CataloguesItem from './Catalogues/Item';
import Profile from './Profile';
import RemindPassword from './RemindPassword';
import Wishes from './Wishes';
import Instructions from './Instructions';
import Balance from './Balance';
*/

import CoreLayout from '../layouts/CoreLayout';

import Login from '../routes/Login';
import RemindPassword from '../routes/RemindPassword';
import CommunicationEvents from '../routes/CommunicationEvents';
import Companies from '../routes/Companies';
import Balance from '../routes/Balance';
import Catalogues from '../routes/Catalogues';
import Clients from './Clients';
import Users from './Users';
import Services from './Services';
import Statistic from './Statistic';
import DrumsList from './ServiceTemplateGroups';
import CarGallery from './CarGallery';
import PreLoader from '../components/PreLoader';
import References from './References';
import Expenses from './Expenses';
import Packets from './Packets';
import Metrics from './Metrics';

class Loader extends Component {
    render() {
        return (
            <PreLoader trigger />
        );
    }
}

class IndexRouter extends Component {
    render() {
        return (
            <Switch>
                <Route path='/login' component={Login} />
                <Route path='/remind-password' component={RemindPassword} />
                <CoreLayout>
                    <Suspense fallback={<Loader />}> { /* your usual react-router v4/v5 routing */ }
                        <CommunicationEvents {...this.props} />
                        <Companies />
                        <Catalogues {...this.props} />
                        <Services />
                        <Clients {...this.props} />
                        <Users {...this.props} />
                        <Balance {...this.props} />
                        <DrumsList {...this.props} />
                        <Statistic {...this.props} />
                        <CarGallery {...this.props} />
                        <References {...this.props} />
                        <Expenses {...this.props} />
                        <Metrics {...this.props} />
                        <Packets {...this.props} />
                    </Suspense>
                </CoreLayout>
                <Redirect to='/statistic' from='/' />
            </Switch>
        );
    }
}

/**
 * const createRoutes = function(store) {
    return {
        path: '/',
        component: CoreLayout,
        indexRoute: Home(store),
        childRoutes: [
            Login(store),
            StatisticGraph(store),
            Users(store),
            CompanyOrders(store),
            CommunicationEvents(store),
            References(store),
            Services(store),
            ServiceTemplates(store),
            ServiceTemplateGroups(store),
            ServiceTemplateGroupsCreate(store),
            ServiceTemplateGroupsItem(store),
            Catalogues(store),
            CataloguesItem(store),
            Profile(store),
            RemindPassword(store),
            Wishes(store),
            Instructions(store),
            Balance(store),
            NotFound(store)
        ]
    };
}; */

export default IndexRouter;
