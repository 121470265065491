import React, { lazy, Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProlongationsReducer from './modules/prolongations.reducer';
import PropTypes from 'prop-types';

const LazyCataloguesList = lazy(() => import('./components/CataloguesList'));
const LazyGuideBook = lazy(() => import('./components/GuideBook/GuideBook'));
const LazyProlongationSettings = lazy(() => import('./components/ProlongationSettings/ProlongationSettingsList'));
const LazyProlongationSettingsItem = lazy(() => import('./components/ProlongationSettings/ProlongationSettingsItem'));

class Catalogues extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('prolongations', ProlongationsReducer);
        return (
            <Switch>
                <Route exact path='/catalogues/' render={() => (
                    <Redirect to="/catalogues/auto"/>
                )} />
                <Route path='/catalogues/auto' component={LazyCataloguesList} />
                <Route path='/catalogues/style' component={LazyCataloguesList} />
                <Route path='/catalogues/food' component={LazyCataloguesList} />
                <Route exact path='/catalogues/guidebooks' component={LazyGuideBook} />
                <Route exact path='/catalogues/prolongation' component={LazyProlongationSettings} />
                <Route exact path='/catalogues/prolongation/business-type/:id'>
                    <Switch>
                        <Route path='/catalogues/prolongation/business-type/:id/' render={props => <LazyProlongationSettingsItem {...props} />} />
                    </Switch>
                </Route>
            </Switch>
        );
    }
}

export default Catalogues;
