import { api, httpBuildQuery } from '../../../utils';

import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_S_TEMPLATES = 'requestServiceTemplates';
const RECEIVE_S_TEMPLATES = 'receiveServiceTemplates';
const RECEIVE_S_TEMPLATES_BY_SEARCH = 'receiveServiceTemplatesBySearch';
const CREATE_SERVICE_TEMPLATE = 'createServiceTemplate';
const SERVICE_TEMPLATE_IS_CREATED = 'serviceTemplateIsCreated';
const SERVICE_TEMPLATE_IS_UPDATED = 'serviceTemplateIsUpdated';
const HIDE_SERVICE_TEMPLATE = 'hideServiceTemplate';
const SERVICE_TEMPLATE_IS_HIDDEN = 'serviceTemplateIsHidden';

const REQUEST_S_T_GROUPS = 'requestServiceTemplateGroups';
const RECEIVE_S_T_GROUPS = 'receiveServiceTemplateGroups';
const RECEIVE_S_T_GROUPS_BY_IDS = 'receiveServiceTemplateGroupsByIds';

const UPDATE_S_T_GROUP = 'updateServiceTemplateGroup';
const S_T_GROUP_IS_UPDATED = 'serviceTemplateGroupIsUpdated';

const CREATE_S_T_GROUP = 'createServiceTemplateGroup';
const S_T_GROUP_IS_CREATED = 'serviceTemplateGroupIsCreated';

// ------------------------------------
// Actions
// ------------------------------------

export const createServiceTemplate = (data) => {
    return function(dispatch) {
        dispatch({ type: CREATE_SERVICE_TEMPLATE });
        return api('/v3/admin/services/templates?expand=image,thumbnails,serviceOptionTemplates', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: SERVICE_TEMPLATE_IS_CREATED, payload: response }));
    };
};

export const updateServiceTemplate = (data, parentId, query) => {
    return function(dispatch) {
        dispatch({ type: CREATE_SERVICE_TEMPLATE });
        return api('/v1/admin/service-templates/' + data.id + (_.isEmpty(query) ? '' : ('?' + httpBuildQuery(query))), {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => {
                dispatch({ type: SERVICE_TEMPLATE_IS_UPDATED, template: data, payload: response, parentId: parentId });
            });
    };
};

export const hideServiceTemplate = (id, keepResourced = 1) => {
    return function(dispatch) {
        dispatch({ type: HIDE_SERVICE_TEMPLATE });
        return api('/v1/admin/service-templates/' + id + '?keep_resourced?=' + keepResourced, {
            method: 'DELETE'
        }, dispatch)
            .then(response => {
                dispatch({ type: SERVICE_TEMPLATE_IS_HIDDEN, payload: response });
            });
    };
};

export const fetchServiceTemplates = (data) => {
    return function(dispatch) {
        dispatch(requestServiceTemplates());
        return api('/v1/admin/service-templates' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveServiceTemplates(response)));
    };
};

export const fetchServiceTemplatesBySearch = (search, limit) => {
    return function(dispatch) {
        dispatch(requestServiceTemplates());
        // eslint-disable-next-line multiline-ternary
        return api('/v1/admin/service-templates' + (_.isEmpty(search) ? '' : ('?' + httpBuildQuery({
            show_parent_nodes: false,
            search: search,
            limit: limit
        }))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_S_TEMPLATES_BY_SEARCH, payload: response }));
    };
};

export const requestServiceTemplates = () => {
    return {
        type: REQUEST_S_TEMPLATES
    };
};

function receiveServiceTemplates(response) {
    return {
        type: RECEIVE_S_TEMPLATES,
        payload: response
    };
}

export const fetchServiceTemplateGroups = (data) => {
    data.show_parent_nodes = false;
    data.is_hidden = 'all';
    return function(dispatch) {
        dispatch({ type: REQUEST_S_T_GROUPS });
        return api('/v1/admin/service-template-groups' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_S_T_GROUPS, payload: response }));
    };
};

export const fetchServiceTemplateGroupsByIds = (ids) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_S_T_GROUPS });
        // eslint-disable-next-line multiline-ternary
        return api('/v1/admin/service-template-groups' + (_.isEmpty(ids) ? '' : ('?' + httpBuildQuery({
            show_parent_nodes: false,
            is_hidden: 'all',
            expand: 'serviceTemplates',
            ids: ids,
            root_only: false
        }))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch({ type: RECEIVE_S_T_GROUPS_BY_IDS, payload: response }));
    };
};

export const updateServiceTemplateGroup = (data, query) => {
    return function(dispatch) {
        dispatch({ type: UPDATE_S_T_GROUP });
        return api('/v1/admin/service-template-groups/' + data.id + (_.isEmpty(query) ? '' : ('?' + httpBuildQuery(query))), {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: S_T_GROUP_IS_UPDATED, serviceTemplateGroup: data, payload: response }));
    };
};

export const createServiceTemplateGroup = (data) => {
    return function(dispatch) {
        dispatch({ type: CREATE_S_T_GROUP });
        return api('/v1/admin/service-template-groups/', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch({ type: S_T_GROUP_IS_CREATED, payload: response }));
    };
};

export const actions = {
    fetchServiceTemplates,
    fetchServiceTemplatesBySearch,
    updateServiceTemplate,
    createServiceTemplate,
    hideServiceTemplate,
    fetchServiceTemplateGroups,
    fetchServiceTemplateGroupsByIds,
    updateServiceTemplateGroup,
    createServiceTemplateGroup
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_S_TEMPLATES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplates = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_S_TEMPLATES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplates = false;
        return ({ ...state, fetching: fetching, serviceTemplates: action.payload });
    },

    [RECEIVE_S_TEMPLATES_BY_SEARCH]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplates = false;
        return ({ ...state, fetching: fetching, serviceTemplatesBySearch: action.payload });
    },
    [CREATE_SERVICE_TEMPLATE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplate = true;
        return ({ ...state, fetching: fetching });
    },
    [SERVICE_TEMPLATE_IS_CREATED]: (state, action) => {
        const serviceTemplates = Object.assign({}, state.serviceTemplates);
        if (serviceTemplates.items) {
            serviceTemplates.items.push(action.payload);
        }
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplate = false;
        return ({ ...state, serviceTemplates: serviceTemplates, fetching: fetching });
    },
    [REQUEST_S_T_GROUPS]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplateGroups = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_S_T_GROUPS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplateGroups = false;
        let serviceTemplateGroups = state.serviceTemplateGroups;
        if (action.payload && action.payload.items) {
            action.payload.items = _.sortBy(action.payload.items, 'sort');
            serviceTemplateGroups = action.payload;
        }
        return ({ ...state, fetching: fetching, serviceTemplateGroups: serviceTemplateGroups });
    },
    [RECEIVE_S_T_GROUPS_BY_IDS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.serviceTemplateGroups = false;
        const serviceTemplateGroups = Object.assign([], state.serviceTemplateGroupsByIds);
        _.each(action.payload.items, item => {
            const index = _.findIndex(serviceTemplateGroups, { id: item.id });
            if (index !== -1) {
                serviceTemplateGroups[index] = item;
            } else {
                serviceTemplateGroups.push(item);
            }
        });
        return ({ ...state, fetching: fetching, serviceTemplateGroupsByIds: serviceTemplateGroups });
    },
    [UPDATE_S_T_GROUP]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.updateServiceTemplateGroups = true;
        return ({ ...state, fetching: fetching });
    },
    [S_T_GROUP_IS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.updateServiceTemplateGroups = false;
        const serviceTemplateGroups = _.cloneDeep(state.serviceTemplateGroups);
        const key = _.findKey(_.get(serviceTemplateGroups, 'items', []), { id: action.serviceTemplateGroup.id });
        if (key) {
            serviceTemplateGroups.items[key] = action.payload;
            serviceTemplateGroups.items[key].serviceTemplates = _.sortBy(action.serviceTemplateGroup.serviceTemplates, 'sort');
        }
        serviceTemplateGroups.items = _.sortBy(serviceTemplateGroups.items, 'sort');
        return ({ ...state, fetching: fetching, serviceTemplateGroups: serviceTemplateGroups });
    },
    [CREATE_S_T_GROUP]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplateGroup = true;
        return ({ ...state, fetching: fetching });
    },
    [S_T_GROUP_IS_CREATED]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplateGroup = false;
        return ({ ...state, fetching: fetching });
    },

    [SERVICE_TEMPLATE_IS_UPDATED]: (state, action) => {
        const serviceTemplateGroups = _.cloneDeep(state.serviceTemplateGroups);
        if (action.parentId) {
            const parentGroup = _.find(serviceTemplateGroups.items, { id: action.parentId });
            const parentGroupIndex = _.findIndex(serviceTemplateGroups.items, { id: action.parentId });
            const templateToChange = _.findIndex(parentGroup.serviceTemplates, { id: action.template.id });
            serviceTemplateGroups.items[parentGroupIndex].serviceTemplates[templateToChange] = action.payload;
            serviceTemplateGroups.items[parentGroupIndex].serviceTemplates = _.sortBy(serviceTemplateGroups.items[parentGroupIndex].serviceTemplates, 'sort');
        }
        const fetching = Object.assign({}, state.fetching);
        fetching.createServiceTemplate = false;
        return ({ ...state, fetching: fetching, serviceTemplateGroups: serviceTemplateGroups });
    }
};

const initialState = {
    fetching: {},
    serviceOptionTemplates: null,
    serviceTemplates: null,
    fullServiceTemplates: null,
    references: null,
    serviceTemplatesBySearch: null,
    serviceTemplateGroups: null,
    serviceTemplateGroupsByIds: null,
    catalogues: {},
    cataloguesByIds: []
};

// eslint-disable-next-line default-param-last
export default function serviceTemplateGroupsReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
