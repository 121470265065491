import React, { lazy, Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReferencesReducer from './modules/references.reducer';
import PropTypes from 'prop-types';
import ReferencesLayout from "../../layouts/ReferencesLayout";

const LazyReferences = lazy(() => import('./modules/References.container'));
const LazyGroupEditor = lazy(() => import('./components/GroupEditor'));
const LazyReferenceEditor = lazy(() => import('./components/ReferenceEditor'));

class References extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('references', ReferencesReducer);
        return (
            <Switch>
                <Route path='/references'>
                    <ReferencesLayout>
                    <Route exact path='/references' component={LazyReferences} />
                    <Route path='/references'>
                        <Switch>
                            <Route exact path='/references/create' component={LazyReferenceEditor} />
                            <Route path='/references/groups'>
                                <Switch>
                                    <Route exact path='/references/groups/create' component={LazyGroupEditor} />
                                    <Route path='/references/groups/:id' component={LazyGroupEditor} />
                                </Switch>
                            </Route>
                            <Route path='/references/:id' component={LazyReferenceEditor} />
                        </Switch>
                    </Route>
                </ReferencesLayout>
                </Route>
            </Switch>
        );
    }
}

export default References;
