import React from 'react';
import PropTypes from 'prop-types';
import classes from './preLoader.module.scss';

export const PreLoader = (props) => {
    return (
        <div className={classes.pagerContainer + ' ' + (props.trigger ? classes.on : '')}>
            <div className={classes.layout} />
            <div className={classes.spinnerDoubleRing}>
                <div className={classes.loader}>
                    <div />
                    <div />
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                </div>
            </div>
        </div>
    );
};

PreLoader.propTypes = {
    trigger: PropTypes.bool
};

export default PreLoader;
