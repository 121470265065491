import { connect } from 'react-redux';
import CompanyLayout from './CompanyLayout';
import { actions } from '../../routes/Companies/module/Companies.reducer';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    company: state.companies.company
});

export default withRouter(connect(mapStateToProps, actions)(CompanyLayout));
