import React, { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import clients from './modules/Clients.reducer';
import ClientLayout from '../../layouts/ClientLayout';

const LazyClients = lazy(() => import('./modules/Clients.container'));
const LazyClientOrderReserve = lazy(() => import('./components/ClientOrderReserve/ClientOrderReserve'));
const LazyClientNotifications = lazy(() => import('./components/ClientNotifications/ClientNotifications'));
const LazyClientUser = lazy(() => import('./components/ClientUser/ClientUser'));
const LazyClientCars = lazy(() => import('./components/ClientCars/ClientCars'));
const LazyClientCompanies = lazy(() => import('./components/ClientsCompanies/ClientsCompanies'));
const LazyClientOKK = lazy(() => import('./components/ClientOKK/ClientOKK'));

class Clients extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('clients', clients);
        return (
            <Switch>
                <Route exact path='/clients' component={LazyClients} />
                <Route path='/clients/:id'>
                    <ClientLayout>
                        <Switch>
                            <Route exact path='/clients/:id/' render={props => <LazyClientOrderReserve {...props} />} />
                            <Route exact path='/clients/:id/orders/' render={props => <LazyClientOrderReserve {...props} />} />
                            <Route exact path='/clients/:id/notifications/' render={props => <LazyClientNotifications {...props} />} />
                            <Route exact path='/clients/:id/user/' render={props => <LazyClientUser />} />
                            <Route exact path='/clients/:id/cars/' render={props => <LazyClientCars />} />
                            <Route exact path='/clients/:id/okk-notifications/' render={props => <LazyClientOKK />} />
                            <Route exact path='/clients/:id/companies/' render={props => <LazyClientCompanies />} />
                        </Switch>
                    </ClientLayout>
                </Route>
            </Switch>
        );
    }
}

export default Clients;
