import { api, httpBuildQuery } from '../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------

const REQUEST_STATISTICS = 'requestStatistics';
const RECEIVE_STATISTICS = 'receiveStatistics';

const REQUEST_DETAILED_STATISTICS = 'requestDetailedStatistics';
const RECEIVE_DETAILED_STATISTICS = 'receiveDetailedStatistics';

const REQUEST_STATISTICS_RESERVES = 'requestReserves';
const RECEIVE_STATISTICS_RESERVES = 'receiveReserves';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDetailedStatistics = (data) => {
    return function(dispatch) {
        dispatch({ type: REQUEST_DETAILED_STATISTICS });
        return api('/v4/admin/statistic/company-activity?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(response => {
                dispatch({ type: RECEIVE_DETAILED_STATISTICS, payload: response });
            });
    };
};
export const fetchReserves = (data) => {
    return function(dispatch) {
        dispatch(requestReserves());
        return api('/v3/admin/statistic/company-by-period?' + httpBuildQuery(data), {
            method: 'GET'
        })
            .then(response => {
                dispatch(receiveReserves(response));
            });
    };
};

export const fetchStatistics = (data) => {
    return function(dispatch) {
        dispatch(requestStatistics());
        return api('/v1/admin/statistic?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(response => {
                dispatch(receiveStatistics(response));
            });
    };
};
function requestStatistics() {
    return {
        type: REQUEST_STATISTICS
    };
}
function receiveStatistics(res) {
    return {
        type: RECEIVE_STATISTICS,
        payload: res
    };
}

function requestReserves() {
    return {
        type: REQUEST_STATISTICS_RESERVES
    };
}
function receiveReserves(res) {
    return {
        type: RECEIVE_STATISTICS_RESERVES,
        payload: res
    };
}

export const actions = {
    fetchStatistics,
    fetchDetailedStatistics,
    fetchReserves
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_STATISTICS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_STATISTICS]: (state, action) => {
        return ({ ...state, statistics: action.payload, fetching: false });
    },
    [REQUEST_DETAILED_STATISTICS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_DETAILED_STATISTICS]: (state, action) => {
        return ({ ...state, detailedStatistics: _.get(action.payload, 'statistic'), fetching: false });
    },
    [REQUEST_STATISTICS_RESERVES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_STATISTICS_RESERVES]: (state, payload) => {
        return ({ ...state, statisticsReserves: payload.payload, fetching: false });
    }
};

const initialState = {
    fetching: false,
    statistics: {},
    detailedStatistics: {},
    statisticsReserves: null
};

export default function reducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
