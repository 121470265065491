import {api, httpBuildQuery} from '../../../utils';
import _ from "lodash";
// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_GENERATIONS = 'carGallery:requestGenerations';
const RECEIVE_GENERATIONS = 'carGallery:receiveGenerations';
const RECEIVE_GENERATION = 'carGallery:receiveGeneration';
const RECEIVE_UPDATED_GENERATION = 'carGallery:receiveUpdatedGeneration';
const DELETE_GENERATION = 'carGallery:deleteGeneration';

const REQUEST_CAR_REFERENCES = 'carGallery:requestCarReferences';
const RECEIVE_CAR_REFERENCES = 'carGallery:receiveCarReferences';

const RECEIVE_FILE = 'carGallery:receiveFile';
const DELETE_FILE = 'carGallery:deleteFile';

const SET_FILTER_PARAMS = 'carGallery:setFilterParams';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchGenerations = (data) => {
    return function (dispatch) {
        dispatch(requestGenerations());
        return api('/v4/admin/cars/generations?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveGenerations(res));
                }
            });
    };
};

function requestGenerations() {
    return {
        type: REQUEST_GENERATIONS
    };
}

function receiveGenerations(res, merge = false) {
    return {
        type: RECEIVE_GENERATIONS,
        merge: merge,
        payload: res
    };
}

export const createGeneration = (data) => {
    return function (dispatch) {
        dispatch(requestGenerations());
        return api('/v4/admin/cars/generations', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(receiveGeneration(response)));
    };
};

export const updateGeneration = (data) => {
    return function (dispatch) {
        return api('/v4/admin/cars/generations', {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(receiveUpdatedGeneration(response)));
    };
};

function receiveGeneration(res) {
    return {
        type: RECEIVE_GENERATION,
        payload: res
    };
}

function receiveUpdatedGeneration(res) {
    return {
        type: RECEIVE_UPDATED_GENERATION,
        payload: res
    };
}

export const fetchDeleteGeneration = (generationId) => {
    return function (dispatch) {
        return api('/v4/admin/cars/generations/' + generationId, {
            method: 'DELETE'
        }, dispatch)
            .then(response => dispatch(deleteGeneration(generationId)));
    };
};

function deleteGeneration(generationId) {
    return {
        type: DELETE_GENERATION,
        generationId: generationId
    };
}

export const fetchCarReferences = (e) => {
    if (e) {
        e.preventDefault();
    }
    return function (dispatch) {
        dispatch(requestCarReferences());
        return api('/v1/references/cars', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveCarReferences(response)));
    };
};

function requestCarReferences(response) {
    return {
        type: REQUEST_CAR_REFERENCES,
        payload: response
    };
}

function receiveCarReferences(response) {
    return {
        type: RECEIVE_CAR_REFERENCES,
        payload: response
    };
}

export function receiveFileToGeneration(file, generationId) {
    return {
        type: RECEIVE_FILE,
        generationId: generationId,
        payload: file
    };
}

export function setFilterParams(data) {
    return {
        type: SET_FILTER_PARAMS,
        data: data
    };
}

export const fetchDeleteFile = (fileId, generationId) => {
    return function (dispatch) {
        return api('/v4/admin/cars/generations/' + generationId + '/media/' + fileId, {
            method: 'DELETE'
        }, dispatch)
            .then(response => dispatch(deleteFile(fileId, generationId)));
    };
};

function deleteFile(fileId, generationId) {
    return {
        type: DELETE_FILE,
        fileId: fileId,
        generationId: generationId
    };
}

export const actions = {
    fetchGenerations,
    fetchDeleteGeneration,
    fetchCarReferences,
    createGeneration,
    updateGeneration,
    receiveFileToGeneration,
    fetchDeleteFile
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_GENERATIONS]: (state) => {
        return ({...state, fetching: true});
    },
    [RECEIVE_GENERATIONS]: (state, action) => {
        return ({...state, generations: action.payload, fetching: false});
    },
    [RECEIVE_GENERATION]: (state, action) => {
        let generations = _.cloneDeep(state.generations);
        if (generations == null) {
            generations = {items: [], total: 0};
        }
        if (action.payload) {
            generations.items = [action.payload].concat(generations.items);
            generations.total ++;
        }
        return ({...state, generations: generations, fetching: false});
    },
    [RECEIVE_UPDATED_GENERATION]: (state, action) => {
        let generations = _.cloneDeep(state.generations);
        if (action.payload) {
            const existedKey = _.findIndex(generations.items, {id: action.payload.id});
            if (existedKey !== -1) {
                generations.items[existedKey] = action.payload;
            }
        }
        return ({...state, generations: generations, fetching: false});
    },
    [DELETE_GENERATION]: (state, action) => {
        let generations = _.cloneDeep(state.generations);
        _.remove(generations.items, {id: action.generationId})
        return ({...state, generations: generations, fetching: false});
    },
    [REQUEST_CAR_REFERENCES]: (state) => {
        return ({...state, fetching: true});
    },
    [RECEIVE_CAR_REFERENCES]: (state, action) => {
        return ({...state, carReferences: action.payload, fetching: false});
    },
    [RECEIVE_FILE]: (state, action) => {
        let generations = _.cloneDeep(state.generations);
        let generation = _.find(generations.items, {id: action.generationId});
        if (generation) {
            generation.files.push(action.payload);
        }
        return ({...state, generations: generations});
    },
    [DELETE_FILE]: (state, action) => {
        let generations = _.cloneDeep(state.generations);
        let generation = _.find(generations.items, {id: action.generationId});
        if (generation) {
            generation.files = _.filter(generation.files, (file) => file.id !== action.fileId);
        }
        return ({...state, generations: generations});
    },
    [SET_FILTER_PARAMS]: (state, action) => {
        return ({...state, filterParams: action.data});
    },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    generations: null,
    carReferences: null,
    filterParams: {}
};

export default function CarGalleryReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
