import React from 'react';
import { BreadCrumbs } from '../index';
import style from './HeaderInMainBody.module.scss';
import PropTypes from 'prop-types';

const HeaderInMainBody = ({ title, breadcrumbs, label, description, classes = [] }) => {
    return (
        <div className={style.headerInMainBody + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.headerMain}>
                {
                    breadcrumbs
                        ? <BreadCrumbs breadcrumbs={breadcrumbs} />
                        : ''
                }
                <div className={style.title}>
                    { title }
                </div>
                {
                    label
                        ? (
                            <div className={style.label}>
                                { label }
                            </div>
                        )
                        : ''
                }
            </div>
            {
                description
                    ? (
                        <div className={style.description}>
                            { description }
                        </div>
                    )
                    : ''
            }
        </div>
    );
}

HeaderInMainBody.propTypes = {
    title: PropTypes.string,
    breadcrumbs: PropTypes.array,
    label: PropTypes.string,
    classes: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.node
};

export default HeaderInMainBody;
