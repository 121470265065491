import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classes from './cornerInfo.module.scss';

class CornerInfo extends React.Component {
    render() {
        const client = this.props.client;
        if (client) {
            return (
                <div className={classes.container}>
                    <div className={classes.label}>Телефон:<span className={classes.content}>{ client.phone }</span></div>
                    <div className={classes.label}>Создан:
                        <span className={classes.content}>
                            { client.created_at ? moment(client.created_at).format('DD.MM.YYYY') : '' }
                        </span>
                    </div>
                    <div className={classes.label}>Id:<span className={classes.content}>{ client.id }</span></div>
                    <div className={classes.label}>Интеграция с 1С:<span className={classes.content}>1234, 217865</span>
                    </div>
                    <div className={classes.label}>Ortus:
                        <div className={`${classes.ortus} ${client.is_activated ? classes.active : classes.inActive}`} />
                    </div>
                </div>
            );
        } else return null;
    }
}
CornerInfo.propTypes = {
    client: PropTypes.object
};
const mapStateToProps = (state) => ({
    client: state.clients.client
});

export default (connect(mapStateToProps)(CornerInfo));
