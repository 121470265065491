import { connect } from 'react-redux';
import { actions } from '../modules/remindPassword';

import { withRouter } from 'react-router-dom';
import RemindPassword from './../components/RemindPassword';

const mapStateToProps = (state) => ({
    login: state.remindPassword.login,
    passwordCode: state.remindPassword.passwordCode,
    passwordKey: state.remindPassword.passwordKey,
    reminded: state.remindPassword.reminded,
    error: state.remindPassword.error,
    loginPhone: state.account.login,
    user: state.account.user,
    token: state.account.token
});

export default withRouter(connect(mapStateToProps, actions)(RemindPassword));
