import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from '../../reducers/auth';
import _ from 'lodash';
import { changePassInput, confirmPassCheck, passUpdate, passwordMenuShow, clearAjaxError } from '../../reducers/hangar';

import Header from './Header';

const mapStateToProps = (state) => ({
    partner: state.account.partner,
    showHeaderMenu: state.hangar.showHeaderMenu,
    password: state.hangar.password,
    checkPassStatus: state.hangar.checkPassStatus,
    passwordMenu: state.hangar.passwordMenu,
    showErrors: state.hangar.showErrors,
    ajaxError: state.hangar.ajaxError,
    user: state.account.user,
    token: state.account.token
});

export default withRouter(connect(mapStateToProps, _.merge(actions, {
    changePassInput,
    confirmPassCheck,
    passUpdate,
    passwordMenuShow,
    clearAjaxError
}))(Header));
