import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompanyLayout from '../../layouts/CompanyLayout';

const LazyCompanyEdit = React.lazy(() => import('./Components/CompanyEdit'));
const LazyCompaniesResources = React.lazy(() => import('./Components/Resources'));
const LazyCompaniesClients = React.lazy(() => import('./Components/CompanyClients'));
const LazyCompaniesOrders = React.lazy(() => import('./Components/CompanyOrders'));
const LazyCompaniesList = React.lazy(() => import('./Components/List'));
const LazyCompaniesServices = React.lazy(() => import('./Components/CompanyServices/CompanyServices'));
const CreateCompany = React.lazy(() => import('./Components/CreateCompany'));

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (
        <Switch>
            <Route exact path='/companies' render={props => <LazyCompaniesList {...props} />} />
            <Route exact path='/companies/create' component={CreateCompany} />
            <Route path='/companies/:id'>
                <CompanyLayout>
                    <Switch>
                        <Route exact path={['/companies/:id/info']} render={props => <LazyCompanyEdit {...props} />} />
                        <Route exact path='/companies/:id/resources/' render={props => <LazyCompaniesResources {...props} />} />
                        <Route exact path='/companies/:id/clients/' render={props => <LazyCompaniesClients {...props} />} />
                        <Route exact path='/companies/:id/orders/' render={props => <LazyCompaniesOrders {...props} />} />
                        <Route exact path='/companies/:id/services/' render={props => <LazyCompaniesServices  {...props} />} />
                    </Switch>
                </CompanyLayout>
            </Route>
        </Switch>
    );
};
