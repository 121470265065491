import {api, httpBuildQuery} from '../../../utils';
// import _ from 'lodash';
// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_REFERENCES = 'requestReferences';
const CANCEL_FETCHING = 'cancelFetching';

const REQUEST_CREATING = 'requestCreating';
const RECEIVE_CREATING = 'receiveCreating';

const REQUEST_UPDATING = 'requestUpdating';
const RECEIVE_UPDATING = 'receiveUpdating';

const REQUEST_REFERENCES_GROUPS = 'requestReferencesGroups';
const RECEIVE_REFERENCE_GROUPS = 'receiveReferenceGroups';

const RECEIVE_REFERENCES = 'receiveReferences';

const RECEIVE_REFERENCE = 'receiveReference';

const CHANGE_ACTIVE_GROUP_ID = 'changeActiveGroupId';
const CHANGE_IS_GROUP_LIST_ACTIVE = 'changeIsGroupListActive';

const REMOVE_REFERENCE = 'removeReference';

const CLEAR_REFERENCE = 'clearReference';

const RECEIVE_REFERENCE_GROUP = 'receiveReferenceGroup';
const RECEIVE_NEW_REFERENCE_GROUP = 'receiveNewReferenceGroup';

const REMOVE_REFERENCE_GROUP = 'removeReferenceGroup';

const RECEIVE_REFERENCES_BY_PARAMS = 'receiveReferencesByParams';

const RECEIVE_INPUTS = 'receiveInputs';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchReferenceGroups = () => {
    return function(dispatch) {
        dispatch(requestReferencesGroups());
        return api('/v4/admin/reference-books/groups', {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.length > 0) {
                    return dispatch(receiveReferenceGroups(res.sort((a, b) => a.sort - b.sort)));
                } else {
                    return  dispatch(cancelFetching());
                }
            });
    };
};

function requestReferences() {
    return {
        type: REQUEST_REFERENCES
    };
}

function cancelFetching() {
    return {
        type: CANCEL_FETCHING
    };
}

function requestReferencesGroups() {
    return {
        type: REQUEST_REFERENCES_GROUPS
    };
}

function receiveReferenceGroups(res) {
    return {
        type: RECEIVE_REFERENCE_GROUPS,
        payload: res
    };
}

export const fetchReferencesByGroupId = (groupId) => {
    return function(dispatch) {
        dispatch(requestReferences());
        return api(`/v4/admin/reference-books?with[0]=references&group_ids[0]=${groupId}`, {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.referenceBooks !== null) {
                    return dispatch(receiveReferences(res.referenceBooks, groupId));
                } else {
                    return  dispatch(cancelFetching());
                }
            });
    };
};

export const fetchReferencesByParams = (params = {}) => (dispatch) => {
    const data = {
        ...params,
        with: ['references', 'group']
    }
    dispatch(requestReferences());
    return api(`/v4/admin/reference-books?` + httpBuildQuery(data, '', '&'), {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res && res.referenceBooks !== null) {
                return dispatch(receiveReferencesByParams(res.referenceBooks));
            } else {
                return  dispatch(cancelFetching());
            }
        });

};

export const fetchInputs = () => (dispatch) => {
    dispatch(requestReferences());
    return api(`/v4/admin/reference-books?with[0]=group&group_labels[0]=inputs&&group_labels[0]=inputs&all_types=1`, {
        method: 'GET'
    }, dispatch)
        .then(res => {
            if (res && res.referenceBooks !== null) {
                return dispatch(receiveInputs(res.referenceBooks));
            } else {
                return  dispatch(cancelFetching());
            }
        });

};

function receiveReferences(res, groupId) {
    return {
        type: RECEIVE_REFERENCES,
        payload: res,
        groupId: groupId
    };
}

function receiveReferencesByParams(res) {
    return {
        type: RECEIVE_REFERENCES_BY_PARAMS,
        payload: res
    };
}

function receiveInputs(res) {
    return {
        type: RECEIVE_INPUTS,
        payload: res
    };
}

function changeActiveGroupId(value) {
    return {
        type: CHANGE_ACTIVE_GROUP_ID,
        payload: value,
    };
}

function changeIsGroupListActive(value) {
    return {
        type: CHANGE_IS_GROUP_LIST_ACTIVE,
        payload: value,
    };
}

export const fetchReference = (id) => {
    return function(dispatch) {
        dispatch(requestReferences());
        return api(`/v4/admin/reference-books/${id}?with[0]=references&with[1]=group`, {
            method: 'GET'
        }, dispatch)
            .then(res => {
                dispatch(cancelFetching());
                if (res && res.id !== null) {
                    return dispatch(receiveReference(res));
                }
            });
    };
};

function receiveReference(res, oldGroupId = null) {
    return {
        type: RECEIVE_REFERENCE,
        payload: res,
        oldGroupId: oldGroupId,
    };
}

export const createReference = (data) => {
    return function(dispatch) {
        dispatch(requestCreating());
        return api(`/v4/admin/reference-books?with[0]=references`, {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.id !== null) {
                    dispatch(receiveReference(res));
                } else {
                    dispatch(clearReference());
                }
                dispatch(receiveCreating());
            });
    };
};

function requestCreating() {
    return {
        type: REQUEST_CREATING
    };
}

function receiveCreating() {
    return {
        type: RECEIVE_CREATING
    };
}

function clearReference() {
    return {
        type: CLEAR_REFERENCE
    };
}

export const updateReference = (id, data, oldGroupId) => {
    return function(dispatch) {
        dispatch(requestUpdating());
        return api(`/v4/admin/reference-books/${id}?with[0]=references`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.id !== null) {
                    dispatch(receiveReference(res, oldGroupId));
                }
                dispatch(receiveUpdating());
            });
    };
};

function requestUpdating() {
    return {
        type: REQUEST_UPDATING
    };
}

function receiveUpdating() {
    return {
        type: RECEIVE_UPDATING
    };
}

export const deleteReference = (id) => {
    return function(dispatch) {
        dispatch(requestReferences());
        return api(`/v4/admin/reference-books/${id}`, {
            method: 'DELETE'
        }, dispatch)
            .then(res => {
                dispatch(cancelFetching());
                if (res === true) {
                    dispatch(removeReference(id));
                    return true;
                }
            });
    };
};

function removeReference(id) {
    return {
        type: REMOVE_REFERENCE,
        payload: id,
    };
}

export const createReferenceGroup = (data) => {
    return function(dispatch) {
        dispatch(requestCreating());
        return api(`/v4/admin/reference-books/groups`, {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.id !== null) {
                    dispatch(receiveNewReferenceGroup(res));
                } else {
                    dispatch(receiveCreating());
                }
            });
    };
};

function receiveNewReferenceGroup(res) {
    return {
        type: RECEIVE_NEW_REFERENCE_GROUP,
        payload: res,
    };
}

export const updateReferenceGroup = (id, data) => {
    return function(dispatch) {
        dispatch(requestUpdating());
        return api(`/v4/admin/reference-books/groups/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res && res.id !== null) {
                    dispatch(receiveReferenceGroup(res));
                }
                dispatch(receiveUpdating());
            });
    };
};

function receiveReferenceGroup(res) {
    return {
        type: RECEIVE_REFERENCE_GROUP,
        payload: res,
    };
}

export const deleteReferenceGroup = (id) => {
    return function(dispatch) {
        dispatch(requestReferences());
        return api(`/v4/admin/reference-books/groups/${id}`, {
            method: 'DELETE'
        }, dispatch)
            .then(res => {
                dispatch(cancelFetching());
                if (res === true) {
                    dispatch(removeReferenceGroup(id));
                }
            });
    };
};

function removeReferenceGroup(id) {
    return {
        type: REMOVE_REFERENCE_GROUP,
        payload: id,
    };
}

export const actions = {
    fetchReferenceGroups,
    requestReferences,
    receiveReferenceGroups,
    fetchReferencesByGroupId,
    changeActiveGroupId,
    changeIsGroupListActive,
    fetchReference,
    createReference,
    updateReference,
    deleteReference,
    createReferenceGroup,
    updateReferenceGroup,
    deleteReferenceGroup,
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_REFERENCES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [CANCEL_FETCHING]: (state) => {
        return ({ ...state, fetching: false });
    },
    [REQUEST_REFERENCES_GROUPS]: (state) => {
        return ({ ...state, fetchingGroups: true });
    },
    [RECEIVE_REFERENCE_GROUPS]: (state, action) => {
        return ({ ...state, referenceGroups: action.payload, fetchingGroups: false });
    },
    [RECEIVE_REFERENCES]: (state, action) => {
        let references = Object.assign({}, state.referencesByGroupId);

        references[action.groupId] = action.payload;
        return ({ ...state, referencesByGroupId: references, fetching: false });
    },
    [RECEIVE_REFERENCE]: (state, action) => {
        let references = Object.assign({}, state.referencesByGroupId);
        let reference = action.payload;
        let groupReferences = references[reference.field_group_id] != null ?
            Object.assign([], references[reference.field_group_id]) :
            null;
        let oldReferenceGroup = action.oldGroupId !== null && references[action.oldGroupId] != null ?
            Object.assign([], references[action.oldGroupId]) :
            null;

        if (oldReferenceGroup !== null) {
            oldReferenceGroup = oldReferenceGroup.filter(item => item.id !== reference.id);
            references[action.oldGroupId] = oldReferenceGroup;
        }

        if (groupReferences !== null) {
            const referenceIndex = groupReferences.findIndex(item => item.id === reference.id);
            if (referenceIndex === -1) {
                groupReferences.push(reference);
            } else {
                groupReferences[referenceIndex] = reference;
            }
            references[reference.field_group_id] = groupReferences;
        }

        return ({ ...state, reference: reference, referencesByGroupId: references });
    },
    [CHANGE_ACTIVE_GROUP_ID]: (state, action) => {
        return ({ ...state, activeGroupId: action.payload });
    },
    [CHANGE_IS_GROUP_LIST_ACTIVE]: (state, action) => {
        return ({ ...state, isGroupListActive: action.payload });
    },
    [REMOVE_REFERENCE]: (state, action) => {
        let references = Object.assign({}, state.referencesByGroupId);
        let groupReferences = Object.assign([], references[state.activeGroupId]);

        groupReferences = groupReferences.filter(item => item.id !== action.payload);
        references[state.activeGroupId] = groupReferences;

        return ({ ...state, reference: null, referencesByGroupId: references });
    },
    [REQUEST_CREATING]: (state) => {
        return ({ ...state, creating: true });
    },
    [RECEIVE_CREATING]: (state) => {
        return ({ ...state, creating: false });
    },
    [REQUEST_UPDATING]: (state) => {
        return ({ ...state, updating: true });
    },
    [RECEIVE_UPDATING]: (state) => {
        return ({ ...state, updating: false });
    },
    [CLEAR_REFERENCE]: (state) => {
        return ({ ...state, reference: null });
    },
    [RECEIVE_REFERENCE_GROUP]: (state, action) => {
        let groups = Object.assign([], state.referenceGroups);
        let groupIndex = groups.findIndex(item => item.id === action.payload.id);
        if (groupIndex !== -1) {
            groups[groupIndex] = action.payload;
        }

        groups = groups.sort((a, b) => a.sort - b.sort);

        return ({ ...state, referenceGroups: groups });
    },
    [RECEIVE_NEW_REFERENCE_GROUP]: (state, action) => {
        let groups = Object.assign([], state.referenceGroups);
        groups.push(action.payload);

        groups = groups.sort((a, b) => a.sort - b.sort);

        return ({ ...state, referenceGroups: groups, creating: false });
    },
    [REMOVE_REFERENCE_GROUP]: (state, action) => {
        let references = Object.assign({}, state.referencesByGroupId);
        let groups = Object.assign([], state.referenceGroups);

        delete references[action.payload];
        groups = groups.filter(item => item.id !== action.payload);

        return ({ ...state, referenceGroups: groups, referencesByGroupId: references });
    },
    [RECEIVE_REFERENCES_BY_PARAMS]: (state, action) => {
        const result = action.payload.filter(item => item.group?.label !== 'inputs');
        return ({ ...state, references: [...result], fetching: false });
    },
    [RECEIVE_INPUTS]: (state, action) => {
        return ({ ...state, inputs: action.payload, fetching: false });
    },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    creating: false,
    updating: false,
    fetchingGroups: false,
    referenceGroups: null,
    referencesByGroupId: {},
    references: null,
    inputs: null,
    reference: null,
    activeGroupId: null,
    isGroupListActive: false,
};

export default function ReferencesReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
