import React from 'react';
import PropTypes from 'prop-types';
import style from './Button.module.scss';
import _ from 'lodash';

export function Button({onClick, disabled, hidden, title, className, value, children, textOnMouseOver}) {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            hidden={hidden}
            title={title || ''}
            data-hint={textOnMouseOver}
            className={
                _.map((className || '').split(' '), cn => {
                    if (style[cn]) {
                        return style[cn];
                    } else {
                        return cn;
                    }
                }).join(' ') + ' ' + style.componentButton
            }
        >
            <div>{ value || children }{ disabled }</div>
        </button>
    );
}

Button.propTypes = {
    value: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default Button;

export const ButtonStylized = ({ title, disabled, handleOnClick, children, classes = [] }) => {
    return (
        <button className={style.button + classes.map(item => ' ' + style[item]).join('')} disabled={disabled} onClick={handleOnClick}>
            { title || children }
        </button>
    );
};

ButtonStylized.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    handleOnClick: PropTypes.func,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['marginRight15', 'red', 'transparent', 'withoutBorderRadius', 'minHeight46', 'button30', 'withoutBorder', 'flexStart', 'hoverUnderline', 'marginBottom16']))
};

export const TrashButton = ({ handleOnClick }) => {
    return (
        <ButtonStylized classes={['transparent', 'button30']} handleOnClick={handleOnClick}>
            <div className={style.basket} />
        </ButtonStylized>
    );
}

export const CrossButton = ({ handleOnClick }) => {
    return (
        <ButtonStylized classes={['transparent', 'button30']} handleOnClick={handleOnClick}>
            <div className={style.crossBtn} />
        </ButtonStylized>
    );
}
