import { api, httpBuildQuery } from '../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_USERS = 'requestUsers';
const RECEIVE_USERS = 'receiveUsers';
const REQUEST_USER = 'requestUser';
const RECEIVE_USER = 'receiveUser';

const REQUEST_ROLES = 'requestRoles';
const RECEIVE_ROLES = 'receiveRoles';

const USER_CREATING = 'userCreating';
const USER_CREATED = 'userCreated';

const USER_UPDATING = 'userUpdating';
const USER_UPDATED = 'userUpdated';

// ------------------------------------
// Actions
// ------------------------------------

export const createUser = (data) => {
    return function(dispatch) {
        dispatch(userCreating());
        return api('/v1/admin/users', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => dispatch(userCreated(data, res)));
    };
};

function userCreating() {
    return {
        type: USER_CREATING
    };
}
function userCreated() {
    return {
        type: USER_CREATED
    };
}

export const updateUser = (id, data) => {
    return function(dispatch) {
        dispatch(userUpdating());
        return api('/v1/admin/users/' + id, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => dispatch(userUpdated(res)));
    };
};

function userUpdating() {
    return {
        type: USER_UPDATING
    };
}
function userUpdated(res) {
    return {
        type: USER_UPDATED,
        payload: res
    };
}

export const fetchUser = (id) => {
    return function(dispatch) {
        dispatch(requestUser());
        return api('/v1/admin/users/' + id, {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveUser(res)));
    };
};

function requestUser() {
    return {
        type: REQUEST_USER
    };
}

function receiveUser(res) {
    return {
        type: RECEIVE_USER,
        payload: res
    };
}

export const fetchUsers = (data) => {
    return function(dispatch) {
        dispatch(requestUsers());
        return api('/v1/admin/users?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveUsers(data, res)));
    };
};

function requestUsers() {
    return {
        type: REQUEST_USERS
    };
}

function receiveUsers(data, res) {
    return {
        type: RECEIVE_USERS,
        payload: { data, res }
    };
}

export const fetchRoles = () => {
    return function(dispatch) {
        dispatch(requestRoles());
        return api('/v1/admin/roles', {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveRoles(res)));
    };
};

function requestRoles() {
    return {
        type: REQUEST_ROLES
    };
}

function receiveRoles(res) {
    return {
        type: RECEIVE_ROLES,
        payload: res
    };
}

export const actions = {
    createUser,
    updateUser,
    fetchUsers,
    fetchUser,
    fetchRoles
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_USERS]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.users = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_USERS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.users = false;
        return ({ ...state, users: action.payload, fetching: fetching });
    },
    [REQUEST_USER]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.user = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_USER]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.user = false;
        return ({ ...state, user: action.payload, fetching: fetching });
    },
    [REQUEST_ROLES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.roles = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_ROLES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.roles = false;
        return ({ ...state, roles: action.payload, fetching: fetching });
    },
    [USER_CREATING]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.create = true;
        return ({ ...state, fetching: fetching });
    },
    [USER_CREATED]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.create = false;
        return ({ ...state, fetching: fetching });
    },
    [USER_UPDATING]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.update = true;
        return ({ ...state, fetching: fetching });
    },
    [USER_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);

        fetching.update = false;
        return ({ ...state, user: action.payload.user, fetching: fetching });
    }
};

const initialState = {
    companies: {},
    user: null,
    users: null,
    roles: null,
    fetching: {}
};

// eslint-disable-next-line default-param-last
export default function usersReducer(state = initialState, action) {
    // state = Object.assign({}, initialState, state);
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
