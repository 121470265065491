import { api, httpBuildQuery } from '../../../utils';

import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_COMPANIES = 'requestCompanies';
const RECEIVE_COMPANIES = 'receiveCompanies';
const RECEIVE_COMPANIES_TO_SELECT = 'receiveCompaniesToSelect';

const REQUEST_COMPANY = 'requestCompany';
const RECEIVE_COMPANY = 'receiveCompany';
const MERGE_COMPANY = 'mergeCompany';

const CREATING_COMPANY = 'creatingCompany';
const COMPANY_CREATED = 'companyCreated';

const RECEIVE_DIVISIONS = 'receiveDivisions';

const REQUEST_LOGO = 'requestLogo';
const RECEIVE_LOGO = 'receiveLogo';

const REQUEST_SERVICES = 'requestServices';
const RECEIVE_SERVICES = 'receiveServices';
const SERVICES_IS_UPDATED = 'servicesIsUpdated';

const CHANGE_COMPANY = 'changeCompany';
const CHANGE_COMPANY_FIELD = 'changeCompanyField';

const CLEAR_COMPANY_LOGO = 'clearCompanyLogo';
const CLEAR_COMPANY_COVER = 'clearCompanyCover';

const INITIAL_FIELDS_VALUE = [
    {
        label: 'company_phones',
        value: []
    },
    {
        label: 'company_city',
        value: ''
    },
    {
        label: 'company_address',
        value: {}
    },
    {
        label: 'company_worktime',
        value: '111111111111111111111111111111111111111111111111'
    },
    {
        label: 'company_workdays',
        value: []
    },
    {
        label: 'company_specs',
        value: []
    },
    {
        label: 'company_booking_limit',
        value: 10
    },
    {
        label: 'company_desc',
        value: ''
    },
    {
        label: 'company_short_desc',
        value: ''
    }
];

// ------------------------------------
// Actions
// ------------------------------------

export const createCompany = (data) => {
    return function(dispatch) {
        dispatch(creatingCompany());
        return api('/v1/admin/companies', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch(companyCreated(res));
                }
            });
    };
};

function creatingCompany() {
    return {
        type: CREATING_COMPANY
    };
}

function companyCreated(res) {
    return {
        type: COMPANY_CREATED,
        payload: res
    };
}

export const fetchCompaniesToSelect = (data) => {
    return function(dispatch) {
        dispatch(requestCompanies());
        return api('/v1/admin/companies?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveCompaniesToSelect(res)));
    };
};

export const fetchCompanies = (data) => {
    return function(dispatch) {
        dispatch(requestCompanies());
        return api('/v3/admin/companies?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveCompanies(data, res)));
    };
};

function requestCompanies() {
    return {
        type: REQUEST_COMPANIES
    };
}

function receiveCompanies(data, res) {
    return {
        type: RECEIVE_COMPANIES,
        payload: { data, res }
    };
}

function receiveCompaniesToSelect(res) {
    return {
        type: RECEIVE_COMPANIES_TO_SELECT,
        payload: res
    };
}

export const fetchLogo = (id) => {
    return function(dispatch) {
        dispatch(requestLogo());
        return api('/v1/partner/companies/' + id + '/logo', {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveLogo(response)));
    };
};

function requestLogo() {
    return {
        type: REQUEST_LOGO
    };
}

function receiveLogo(response) {
    return {
        type: RECEIVE_LOGO,
        data: response
    };
}

export const changeCompany = (path, value) => {
    return {
        type: CHANGE_COMPANY,
        path: path,
        value: value
    };
};

export const changeCompanyField = (label, value) => {
    return {
        type: CHANGE_COMPANY_FIELD,
        label: label,
        value: value
    };
};

export const fetchServices = (data) => {
    return function(dispatch) {
        dispatch(requestServices());
        return api('/v1/admin/services' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveServices(response)));
    };
};

export const requestServices = () => {
    return {
        type: REQUEST_SERVICES
    };
};

function receiveServices(response) {
    return {
        type: RECEIVE_SERVICES,
        payload: response
    };
}

export const updateServices = (data) => {
    return function(dispatch) {
        dispatch(requestServices());
        return api('/v1/admin/services', {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(servicesIsUpdated(response, data)));
    };
};

function servicesIsUpdated(response, data) {
    return {
        type: SERVICES_IS_UPDATED,
        payload: response,
        data: data
    };
}

export const saveCompany = (data, id) => {
    return function(dispatch) {
        dispatch(requestCompany());
        return api('/v1/admin/companies/' + id, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(receiveCompany(response)));
    };
};

export const saveCompanyImage = (file, id) => {
    return function(dispatch) {
        dispatch(requestCompany());
        return api('/v1/admin/companies/' + id + '/images', {
            method: 'POST',
            body: JSON.stringify(file)
        }, dispatch)
    };
};
export const deleteCompanyImage = (data, id) => {
    return function(dispatch) {
        dispatch(requestCompany());
        return api('/v1/admin/companies/' + id + '/images', {
            method: 'DELETE',
            body: JSON.stringify(data)
        }, dispatch)
    };
};

export const changeResourceImage = (file, id) => {
    return function(dispatch) {
        // dispatch(requestCompany());
        return api('/v3/admin/resources/' + id + '/avatar', {
            method: 'POST',
            body: JSON.stringify(file)
        }, dispatch)
    };
};

export const fetchCompany = (id, expand) => {
    return function(dispatch) {
        dispatch(requestCompany());
        expand = expand ? '?expand=' + expand.join(',') : '';
        return api(
            '/v3/admin/companies/' + id + expand, {
                method: 'GET'
            }, dispatch)
            .then(response => dispatch(receiveCompany(response)));
    };
};

export const requestCompany = () => {
    return {
        type: REQUEST_COMPANY
    };
};

export const receiveCompany = (response) => {
    return {
        type: RECEIVE_COMPANY,
        payload: response
    };
};
export const mergeCompany = (response) => {
    return {
        type: MERGE_COMPANY,
        payload: response
    };
};

export const actions = {
    fetchCompany,
    fetchCompanies,
    fetchServices,
    updateServices,
    fetchLogo,
    createCompany,
    changeCompany,
    changeCompanyField,
    saveCompany,
    saveCompanyImage,
    deleteCompanyImage,
    changeResourceImage
};

export const companiesActions = {
    fetchCompany,
    fetchCompanies,
    fetchServices,
    updateServices,
    fetchLogo,
    createCompany,
    changeCompany,
    changeCompanyField,
    saveCompany
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_COMPANY]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.company = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_COMPANY]: (state, action) => {
        if (action.payload !== false) {
            const fetching = Object.assign({}, state.fetching);
            fetching.company = false;
            // выбираем активным первое подразделение

            if (_.get(action.payload, 'company.fields', []).length === 0) {
                action.payload.company.fields = INITIAL_FIELDS_VALUE;
            } else {
                _.map(INITIAL_FIELDS_VALUE, field => {
                    if (!_.some(action.payload.company.fields, { label: field.label })) {
                        action.payload.company.fields[4].value.sort();
                        action.payload.company.fields.push(field);
                    }
                });
            }

            const companies = Object.assign({}, state.companies);
            const key = _.findKey(_.get(companies, 'items', []), ['id', action.payload.company.id]);

            if (companies.items) {
                companies.items[key] = action.payload.company;
            }

            return ({
                ...state,
                fetching: fetching,
                company: action.payload.company,
                companies: companies
            });
        } else {
            return ({ ...state });
        }
    },
    [MERGE_COMPANY]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.company = false;
        // выбираем активным первое подразделение
        return ({ ...state, fetching: fetching, company: _.merge(action.payload.company, state.company) });
    },
    [CHANGE_COMPANY]: (state, action) => {
        const company = Object.assign({}, state.company);
        _.set(company, action.path, action.value);
        return ({ ...state, company });
    },
    [CHANGE_COMPANY_FIELD]: (state, action) => {
        const company = Object.assign({}, state.company);
        const fKey = _.findKey(company.fields, { label: action.label });
        company.fields[fKey].value = action.value;
        return ({ ...state, company });
    },
    [RECEIVE_DIVISIONS]: (state, action) => {
        const company = Object.assign({}, state.company);
        company.divisions = action.payload;
        return ({ ...state, company: company });
    },
    [REQUEST_LOGO]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.logo = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_LOGO]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.logo = false;
        return ({ ...state, logo: action.data.logo, fetching: fetching });
    },
    [CLEAR_COMPANY_LOGO]: (state) => {
        return ({ ...state, logo: null });
    },
    [CLEAR_COMPANY_COVER]: (state) => {
        const company = Object.assign({}, state.company);
        company.cover_id = 0;
        return ({ ...state, company: company });
    },
    [REQUEST_COMPANIES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.companies = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_COMPANIES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.companies = false;
        return ({ ...state, companies: _.merge(action.payload.res, action.payload.data), fetching: fetching });
    },
    [RECEIVE_COMPANIES_TO_SELECT]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.companies = false;
        return ({ ...state, companiesToSelect: action.payload, fetching: fetching });
    },
    [CREATING_COMPANY]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.companies = true;
        return ({ ...state, fetching: fetching });
    },
    [COMPANY_CREATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        action.payload.company.fields = INITIAL_FIELDS_VALUE;
        fetching.companies = false;
        return ({ ...state, fetching: fetching, company: action.payload.company });
    },
    [REQUEST_SERVICES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_SERVICES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.services = false;
        return ({ ...state, fetching: fetching, services: action.payload });
    },
    [SERVICES_IS_UPDATED]: (state, payload) => {
        const services = _.cloneDeep(state.services);
        const key = _.findIndex(_.get(services, 'items', []), { id: payload.data.services[0].id });
        if (key !== -1) {
            services.items[key] = payload.data.services[0];
        }
        const fetching = Object.assign({}, state.fetching);
        fetching.services = false;
        return ({ ...state, fetching: fetching, services: services });
    }
};

const initialState = {
    company: {},
    schedules: {},
    fetching: { companies: false },
    employees: null,
    companies: {},
    companiesToSelect: {},
    services: null
};

// eslint-disable-next-line default-param-last
export default function companyReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
