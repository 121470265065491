import { api, httpBuildQuery } from '../../../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_COMMUNICATION_EVENTS = 'requestCommunicationEvents';
const RECEIVE_COMMUNICATION_EVENTS = 'receiveCommunicationEvents';

const RECEIVE_STATUSES_REASONS = 'receiveStatusesAndReasons';

const EVENT_STATUS_ONCHECK = 'eventStatusOnCheck';
const UPDATER_FILTERS = 'updaterFilters';
const UPDATE_SEARCHVALUES = 'updateSearchValues';
const ADD_FILTER = 'addFilter';
const HANDLE_CLICK = 'handleClick';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCommunicationEvents = (data) => {
    return function(dispatch) {
        dispatch(requestCommunicationEvents());
        return api('/v3/admin/communication?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveCommunicationEvents(res));
                }
            });
    };
};

function requestCommunicationEvents() {
    return {
        type: REQUEST_COMMUNICATION_EVENTS
    };
}

function receiveCommunicationEvents(res, merge = false) {
    return {
        type: RECEIVE_COMMUNICATION_EVENTS,
        merge: merge,
        payload: res
    };
}

export const refreshCommunicationEvent = (id) => {
    return function(dispatch) {
        return api('/v3/admin/communication?id=' + id, {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res && res.items) {
                    return dispatch(receiveCommunicationEvents(res, true));
                }
            });
    };
};

export const checkEventStatus = (data) => {
    return function(dispatch) {
        dispatch({ type: EVENT_STATUS_ONCHECK, id: data.communication_event_id });
        return api('/v3/admin/communication/status-check?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch);
    };
};

export const fetchStatusesAndReasons = () => {
    return function(dispatch) {
        return api('/v3/admin/communication/reasons-statuses', {
            method: 'GET'
        }, dispatch).then((res) => {
            return dispatch({ type: RECEIVE_STATUSES_REASONS, payload: res });
        });
    };
};

export const updaterFilters = (newFilters) => {
    return {
        type: UPDATER_FILTERS,
        payload: newFilters
    };
};
export const addFilters = (newFilter) => {
    return {
        type: ADD_FILTER,
        payload: newFilter
    };
};
export const updateSearchValues = (searchValues) => {
    return {
        type: UPDATE_SEARCHVALUES,
        payload: searchValues
    };
};
export const handleClick = (id) => {
    return {
        type: HANDLE_CLICK,
        payload: id
    };
};

export const actions = {
    fetchStatusesAndReasons,
    fetchCommunicationEvents,
    refreshCommunicationEvent,
    checkEventStatus,
    updaterFilters,
    updateSearchValues,
    addFilters,
    handleClick
};
export const actionsCommunications = {
    fetchStatusesAndReasons,
    fetchCommunicationEvents,
    refreshCommunicationEvent,
    checkEventStatus,
    updaterFilters,
    updateSearchValues,
    addFilters,
    handleClick
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_COMMUNICATION_EVENTS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [EVENT_STATUS_ONCHECK]: (state, action) => {
        const communicationEvents = Object.assign({}, state.communicationEvents);
        const itemKey = _.findIndex(communicationEvents.items, { id: action.id });
        if (itemKey !== -1) {
            communicationEvents.items[itemKey].ON_STATUS_CHECK = true;
        }
        return ({ ...state, communicationEvents: communicationEvents, fetching: false });
    },
    [RECEIVE_COMMUNICATION_EVENTS]: (state, action) => {
        let communicationEvents;
        if (action.merge) {
            communicationEvents = Object.assign({}, state.communicationEvents);
            _.each(action.payload.items, event => {
                const existsKey = _.findIndex(communicationEvents.items, { id: event.id });
                if (existsKey !== -1) {
                    communicationEvents.items[existsKey] = event;
                }
            });
        } else {
            communicationEvents = action.payload;
        }
        return ({ ...state, communicationEvents: communicationEvents, fetching: false });
    },
    [RECEIVE_STATUSES_REASONS]: (state, action) => {
        return ({ ...state, statusesAndReasons: action.payload, fetching: false });
    },
    [UPDATER_FILTERS]: (state, action) => {
        return ({ ...state, filters: action.payload });
    },
    [UPDATE_SEARCHVALUES]: (state, action) => {
        return ({ ...state, searchValues: action.payload });
    },

    [ADD_FILTER]: (state, action) => {
        return ({ ...state, filters: action.payload });
    },
    [HANDLE_CLICK]: (state, action) => {
        const id = action.payload;
        const visibleMessageIds = [...state.visibleMessageIds];
        function checker(visibleMessageIds, id) {
            return visibleMessageIds.some((i) => i === id);
        }

        const result = checker(visibleMessageIds, id);
        if (result) {
            const messageIndex = visibleMessageIds.indexOf(id);
            visibleMessageIds.splice(messageIndex, 1);// Вместо полного обнуления найти и убрать нужное
        } else {
            visibleMessageIds.push(id);
        }
        return ({ ...state, visibleMessageIds: visibleMessageIds });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    communicationEvents: null,
    checkingStatus: [],
    statusesAndReasons: null,
    filters: [],
    searchValues: {},
    visibleMessageIds: []
};

// eslint-disable-next-line default-param-last
export default function communicationEventsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
