import React, { lazy, Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PacketsReducer from './modules/packets.reducer';
import PropTypes from 'prop-types';

const LazyPacketsList = lazy(() => import('./components/PacketsList'));
const LazyPacketEditor = lazy(() => import('./components/PacketEditor'));

class Packets extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('packets', PacketsReducer);
        return (
            <Switch>
                <Route path='/packets'>
                    <Route exact path='/packets' component={LazyPacketsList} />
                    <Route path='/packets'>
                        <Switch>
                            <Route path='/packets/create' component={LazyPacketEditor} />
                            <Route path='/packets/:id' component={LazyPacketEditor} />
                        </Switch>
                    </Route>
                </Route>
            </Switch>
        );
    }
}

export default Packets;
