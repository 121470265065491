import React, { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import users from './modules/Users.reducer';

const LazyUsers = lazy(() => import('./modules/Users.container'));

class Users extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('users', users);
        return (
            <Switch>
                <Route exact path='/users' component={LazyUsers} />
            </Switch>
        );
    }
}

export default Users;
