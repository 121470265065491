import { api } from './../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
const CHANGE_VALUE = 'changeValue';
const CHANGE_REMIND = 'changeRemind';
const CHANGE_REMIND_ON_SEND_CODE = 'changeRemindOnSendCode';
const SET_USER = 'setUser';
const SET_REMIND_ERROR = 'setRemindError';
const SET_PHONE = 'setPhone';

// ------------------------------------
// Actions
// ------------------------------------

export const submitPhone = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().remindPassword;
        return api('/admin/password/restore', {
            method: 'post',
            body: JSON.stringify({ phone: state.login.replace(/([^0-9+]*)/ig, '') })
        }, dispatch)
            .then(res => {
                if (res && res.confirmData && res.confirmData.password_key && res.confirmData.id) {
                    dispatch(changeRemindOnSendCode(res.confirmData));
                } else {
                    dispatch(setRemindError(getState().hangar.ajaxError.response.message));
                }
            });
    };
};

export const submitCode = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().remindPassword;
        return api('/admin/password/confirm', {
            method: 'post',
            body: JSON.stringify({ id: state.userId, passwordCode: state.passwordCode, passwordKey: state.passwordKey })
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch(setUser(res));
                } else {
                    dispatch(setRemindError(getState().hangar.ajaxError.response.message));
                }
            });
    };
};

export const changeRemind = (remindStatus) => {
    return {
        type: CHANGE_REMIND,
        reminded: remindStatus
    };
};

function changeRemindOnSendCode(res) {
    return {
        type: CHANGE_REMIND_ON_SEND_CODE,
        payload: res
    };
}

export const changeValue = (e) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const setUser = (tokenAndUser) => {
    return {
        type: SET_USER,
        payload: tokenAndUser
    };
};

export const setRemindError = (error) => {
    return {
        type: SET_REMIND_ERROR,
        error: error
    };
};

export const setPhone = (phone) => {
    return {
        type: SET_PHONE,
        payload: phone
    };
};

export const actions = {
    changeValue,
    submitPhone,
    submitCode,
    changeRemind,
    setRemindError,
    setPhone,
    setUser
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, [action.payload.name]: action.payload.value, error: null });
    },
    [CHANGE_REMIND]: (state, action) => {
        return ({ ...state, reminded: action.reminded, error: null });
    },
    [CHANGE_REMIND_ON_SEND_CODE]: (state, action) => {
        return ({
            ...state,
            reminded: 'sendCode',
            userId: action.payload.id,
            passwordKey: action.payload.password_key,
            error: null
        });
    },
    [SET_REMIND_ERROR]: (state, action) => {
        return ({
            ...state,
            error: action.error
        });
    },
    [SET_PHONE]: (state, action) => {
        return ({
            ...state,
            phone: action.payload
        });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    phone: '',
    passwordKey: null,
    passwordCode: '',
    partnerId: '',
    reminded: 'sendPhone', // sendPhone, sendCode
    error: null
};

// eslint-disable-next-line default-param-last
export default function remindPasswordReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
