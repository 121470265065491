import { api } from './../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_ROLES = 'requestRoles';
const RECEIVE_ROLES = 'receiveRoles';

const REQUEST_ROLE = 'requestRole';
const RECEIVE_ROLE = 'receiveRole';

const REQUEST_PERMISSIONS = 'requestPermissions';
const RECEIVE_PERMISSIONS = 'receivePermissions';

const ROLE_CREATING = 'roleCreating';
const ROLE_CREATED = 'roleCreated';

const ROLE_UPDATING = 'roleUpdating';
const ROLE_UPDATED = 'roleUpdated';
const ROLE_PERMISSIONS_UPDATED = 'rolePermissionsUpdated';

// ------------------------------------
// Actions
// ------------------------------------

export const createRole = (data) => {
    return function(dispatch) {
        dispatch(roleCreating());
        return api('/v1/admin/roles', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => dispatch(roleCreated(data, res)));
    };
};

function roleCreating() {
    return {
        type: ROLE_CREATING
    };
}
function roleCreated() {
    return {
        type: ROLE_CREATED
    };
}

export const updateRole = (id, data) => {
    return function(dispatch) {
        dispatch(roleUpdating());
        return api('/v1/admin/roles/' + id, {
            method: 'PUT',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => dispatch(roleUpdated(res)));
    };
};

function roleUpdating() {
    return {
        type: ROLE_UPDATING
    };
}
function roleUpdated(res) {
    return {
        type: ROLE_UPDATED,
        payload: res
    };
}

export const updateRolePermissions = (id, permissions) => {
    return function(dispatch) {
        dispatch(roleUpdating());
        return api('/v1/admin/roles/' + id + '/permissions', {
            method: 'PUT',
            body: JSON.stringify(permissions)
        }, dispatch)
            .then(res => dispatch(rolePermissionsUpdated(res, id)));
    };
};

function rolePermissionsUpdated(res, id) {
    return {
        type: ROLE_PERMISSIONS_UPDATED,
        payload: {
            permissions: res,
            id: id
        }
    };
}

export const fetchRole = (id) => {
    return function(dispatch) {
        dispatch(requestRole());
        return api('/v1/admin/roles/' + id, {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveRole(res)));
    };
};

function requestRole() {
    return {
        type: REQUEST_ROLE
    };
}

function receiveRole(res) {
    return {
        type: RECEIVE_ROLE,
        payload: res
    };
}

export const fetchRoles = () => {
    return function(dispatch) {
        dispatch(requestRoles());
        return api('/v1/admin/roles', {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receiveRoles(res)));
    };
};

function requestRoles() {
    return {
        type: REQUEST_ROLES
    };
}

function receiveRoles(res) {
    return {
        type: RECEIVE_ROLES,
        payload: res
    };
}

//= ==========Получение разрешений=======================================================================================

export const fetchPermissions = () => {
    return function(dispatch) {
        dispatch(requestPermissions());
        return api('/v1/admin/permissions', {
            method: 'GET'
        }, dispatch)
            .then(res => dispatch(receivePermissions(res)));
    };
};

function requestPermissions() {
    return {
        type: REQUEST_PERMISSIONS
    };
}

function receivePermissions(res) {
    return {
        type: RECEIVE_PERMISSIONS,
        payload: res
    };
}

export const actions = {
    createRole,
    updateRole,
    updateRolePermissions,
    fetchRoles,
    fetchRole,
    fetchPermissions
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_ROLES]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.roles = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_ROLES]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.roles = false;
        return ({ ...state, roles: action.payload, fetching: fetching });
    },
    [REQUEST_ROLE]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.role = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_ROLE]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.role = false;
        return ({ ...state, role: action.payload, fetching: fetching });
    },
    [ROLE_CREATING]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.create = true;
        return ({ ...state, fetching: fetching });
    },
    [ROLE_CREATED]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.create = false;
        return ({ ...state, fetching: fetching });
    },
    [ROLE_UPDATING]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.update = true;
        return ({ ...state, fetching: fetching });
    },
    [ROLE_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        const roles = Object.assign({}, state.roles);
        const role = _.find(roles.items, { id: action.payload.role.id });
        _.merge(role, action.payload.role);
        fetching.update = false;
        return ({ ...state, roles: roles, fetching: fetching });
    },
    [ROLE_PERMISSIONS_UPDATED]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        const roles = Object.assign({}, state.roles);
        const role = _.find(roles.items, { id: action.payload.id });
        _.merge(role.perms, action.payload.permissions);
        fetching.update = false;
        return ({ ...state, roles: roles, fetching: fetching });
    },
    [REQUEST_PERMISSIONS]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.permissions = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_PERMISSIONS]: (state, action) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.permissions = false;
        return ({ ...state, permissions: action.payload, fetching: fetching });
    }
};

const initialState = {
    roles: null,
    role: null,
    permissions: null,
    fetching: {}
};

export default function usersReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
