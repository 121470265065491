import React from 'react';
import PropTypes from 'prop-types';
import PreLoader from '../../components/PreLoader/PreLoader';

const MetricsLayout = ({
    fetching,
    children
}) => {

    return (
        <div>
            {children}
            <PreLoader trigger={fetching} />
        </div>
    );
}


MetricsLayout.propTypes = {
    fetching: PropTypes.bool,
    children: PropTypes.node,
};

export default MetricsLayout;
