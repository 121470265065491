import { api, httpBuildQuery } from '../../../utils';

// ------------------------------------
// Constants
// ------------------------------------
const CANCEL_FETCHING = 'cancelFetching';
const SAVE_REPORT = 'saveReport';
const SAVE_REFID = 'saveRefId';
const CLEAR_REPORT = 'clearReport';
const REQUEST_EXPORT = 'requestExport';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchUsersExports = (data) => (dispatch) => {
    if (!data?.to || !data?.metrics_type) {
        return;
    }
    if (!data?.from) {
       delete data?.from;
    }
    dispatch(requestExport());
    return api('/v4/admin/metrics' + (data && '?') + httpBuildQuery(data, '', '&'), {
        method: 'GET'
    }, dispatch)
        .then(res => {
            dispatch(cancelFetching());
            if (res) {
                return res;
            } else {
                return false;
            }
        });
};

export const fetchProlongationsActivateExport = (data) => (dispatch) => {
    if (!data?.from || !data?.to || !data?.metrics_type) {
        return;
    }
    dispatch(requestExport());
    return api('/v4/admin/metrics/prolongations-activate-export' + (data && '?') + httpBuildQuery(data, '', '&'), {
        method: 'GET'
    }, dispatch)
        .then((data) => {
            if (data?.refId) {
                return dispatch(saveRefId(data?.refId));
            }
        }).catch((err) => {
            dispatch(cancelFetching());
            return err;
        });
};

export const clearReport = () => (dispatch) => {
    dispatch(clearReportProlongation());
}

export const fetchProlongationsExport = (refId) => (dispatch) => {
        return api(`/v4/admin/metrics/prolongations-export?refId=` + refId, {
            method: 'GET'
        }, dispatch, false, true)
            .then(report => {
                if (report) {
                    dispatch(cancelFetching());
                    dispatch(saveReport(report));
                    return true;
                } else {
                    return false;
                }
        })
            .catch((err) => {
                dispatch(cancelFetching());
                return err;
            });
}

function requestExport() {
    return {
        type: REQUEST_EXPORT
    };
}

function cancelFetching() {
    return {
        type: CANCEL_FETCHING
    };
}
function saveReport(report) {
    return {
        type: SAVE_REPORT,
        payload: report
    };
}
function saveRefId(refId) {
    return {
        type: SAVE_REFID,
        payload: refId
    };
}
function clearReportProlongation(report) {
    return {
        type: CLEAR_REPORT,
        payload: report
    };
}

export const actions = {
    fetchProlongationsExport,
    fetchProlongationsActivateExport,
    fetchUsersExports,
    clearReport
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CANCEL_FETCHING]: (state) => {
        return ({ ...state, fetching: false });
    },
    [REQUEST_EXPORT]: (state) => {
        return ({ ...state, fetching: true });
    },
    [SAVE_REFID]: (state, action) => {
        if (action.payload) {
            return ({ ...state, refId: action.payload, report: null });
        }
        return ({
            ...state
        });
    },
    [SAVE_REPORT]: (state, action) => {
        if (action.payload) {
            return ({ ...state, report: action.payload, refId: null, fetching: false });
        }
        return ({
            ...state
        });
    },
    [CLEAR_REPORT]: (state) => {
        return ({ ...state, report: null });
    },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    fetching: false,
    report: null,
    refId: null,
};

export default function MetricsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
