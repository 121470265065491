import React from 'react';
import PropTypes from 'prop-types';
import Company from '../../routes/Companies/Components/Company';
import _ from 'lodash';
import PreLoader from '../../components/PreLoader/PreLoader';

class CompanyLayout extends React.Component {
    componentDidMount() {
        this.props.fetchCompany(this.props.match.params.id, ['files', 'files.thumbs', 'services', 'resources', 'city', 'city.region']);
    }

    render() {
        const company = this.props.company;
        if (!_.isEmpty(company)) {
            return (
                <Company
                    company={this.props.company}
                >
                    { this.props.children }
                </Company>
            );
        } else {
            return (
                <PreLoader trigger />
            );
        }
    }
}

CompanyLayout.propTypes = {
    company: PropTypes.object,
    fetchCompany: PropTypes.func,
    match: PropTypes.object,
    children: PropTypes.object
};

export default CompanyLayout;
