import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InputElement from 'react-input-mask';
import classes from './remindPassword.module.scss';

class RemindPassword extends React.Component {
    componentDidMount() {
        this.props.setPhone(this.props.loginPhone);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user !== this.props.user && prevProps.token !== this.props.token) {
            this.props.history.push('/');
        }
    }

    handleSubmitPhone = (e) => {
        if (!this.props.loginPhone) {
            this.props.setRemindError('Введите номер телефона');
        } else if (this.props.loginPhone.replace(/([^0-9+]*)/ig, '').length < 12) {
            this.props.setRemindError('Указанный пользователь не найден');
        } else {
            this.props.submitPhone(e);
        }
    };

    submitCode(e) {
        if (!this.props.passwordCode) {
            this.props.setRemindError('Введите sms код');
        } else if (!/^\d{4}$/.test(this.props.passwordCode)) {
            this.props.setRemindError('Неправильный sms код');
        } else {
            this.props.submitCode(e);
        }
    }

    handleChangeValue = (e) => {
        this.props.changeValue(e);
    };

    render() {
        let loginLength = this.props.loginPhone;
        loginLength = parseInt(loginLength.replace(/_/g, '').length);
        let content;
        switch (this.props.reminded) {
        case 'sendCode':
            content = (
                <div className={classes.mainContainer}>
                    <Link to='/login'>
                        <div className={classes.typeLoginPassLabel}>
                            ввести логин и пароль
                        </div>
                    </Link>
                    <div className={` ${classes.copImage} ${this.props.error ? classes.error : ''}`} />
                    <div className={classes.errorLabel}>
                        { this.props.error ? 'Неверный смс код' : null }
                    </div>
                    <div className={classes.loginContainer}>
                        <input
                            type='text'
                            name='passwordCode'
                            placeholder='sms код'
                            value={this.props.passwordCode}
                            onChange={this.handleChangeValue}
                            className={`${classes.loginBox} ${this.props.error ? classes.error : ''} ${this.props.passwordCode.length > 3 ? classes.full : ''}`}
                            onKeyPress={
                                e => {
                                    return e.key === 'Enter' ? this.props.submitCode(e) : null;
                                }
                            }
                        />
                    </div>
                    <button
                        onClick={
                            e => {
                                this.submitCode(e);
                            }
                        }
                        className={classes.submit}
                        disabled={this.props.passwordCode.length < 1}
                    >
                        Подтвердить
                    </button>
                </div>
            );
            break;
        case 'sendPhone':
        default:
            content = (
                <div className={classes.mainContainer}>
                    <Link to='/login'>
                        <div className={classes.typeLoginPassLabel}>
                            ввести логин и пароль
                        </div>
                    </Link>
                    <div className={` ${classes.copImage} ${this.props.error ? classes.error : ''}`} />
                    <div className={classes.errorLabel}>
                        { this.props.error ? 'Неверный логин или пароль' : null }
                    </div>
                    <div className={classes.loginContainer}>
                        <InputElement
                            type='text'
                            name='login'
                            placeholder='+7 (___) ___-__-__'
                            value={this.props.login || ''}
                            onChange={this.handleChangeValue}
                            mask='+7 (999) 999-99-99'
                            className={`${classes.loginBox} ${this.props.error ? classes.error : ''} ${loginLength === 18 ? classes.full : ''}`}
                            onKeyPress={
                                e => {
                                    return e.key === 'Enter' ? this.props.submitPhone(e) : null;
                                }
                            }
                        />
                        <span className='glyphicon glyphicon-envelope form-control-feedback' />
                    </div>
                    <button
                        onClick={this.handleSubmitPhone}
                        className={classes.submit}
                        disabled={loginLength !== 18}
                    >
                        Выслать код
                    </button>
                </div>
            );
            break;
        }
        return (
            <>
                <div>
                    <div className={classes.mainLabel}>Control 2.0</div>
                    <div className={classes.secondLabel}>Контрольная панель проекта ORTUS</div>
                </div>
                { content }
            </>
        );
    }
}

RemindPassword.propTypes = {
    login: PropTypes.string,
    history: PropTypes.any,
    loginPhone: PropTypes.string.isRequired,
    passwordCode: PropTypes.string,
    reminded: PropTypes.string,
    error: PropTypes.string,
    submitPhone: PropTypes.func.isRequired,
    changeValue: PropTypes.func.isRequired,
    setPhone: PropTypes.func.isRequired,
    setRemindError: PropTypes.func.isRequired,
    submitCode: PropTypes.func.isRequired,
    user: PropTypes.object,
    token: PropTypes.string
};

export default RemindPassword;
