import { connect } from 'react-redux';
import MetricsLayout from './MetricsLayout';
import { fetchProlongationsExport, fetchUsersExports } from '../../routes/Metrics/modules/metrics.reducer';


const mapStateToProps = (state) => ({
    fetching: state.metrics.fetching,
});

export default connect(mapStateToProps, { fetchProlongationsExport, fetchUsersExports })(MetricsLayout);
