import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from '../modules/login';
import { clearAjaxError } from '../../../reducers/hangar';
import Login from './../components/Login';

const mapStateToProps = (state) => ({
    login: state.account.login,
    password: state.account.password,
    user: state.account.user,
    token: state.account.token,
    error: state.account.error
});

export default withRouter(connect(mapStateToProps, { ...actions, clearAjaxError })(Login));
