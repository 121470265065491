import { connect } from 'react-redux';
import FrontFrame from './FrontFrame';
import _ from 'lodash';

const mapStateToProps = (state) => ({
    companiesTotal: _.get(state.companies, 'companies.total', null),
    user: state.account.user
});

export default connect(mapStateToProps, {})(FrontFrame);
