import React, { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import carGallery from './modules/CarGallery.reducer';

const LazyCarGallery = lazy(() => import('./modules/CarGallery.container'));

class CarGallery extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('carGallery', carGallery);
        return (
            <Switch>
                <Route exact path='/car-gallery' component={LazyCarGallery} />
            </Switch>
        );
    }
}

export default CarGallery;
