import React, { lazy, Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import DrumsReducer from './modules/ServiceTemplateGroups.reducer';
import PropTypes from 'prop-types';

const LazyDrumsList = lazy(() => import('./components/ServiceTemplateGroupsList/ServiceTemplateGroupsList'));

class ServiceTemplateGroups extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('serviceTemplateGroups', DrumsReducer);
        return (
            <Switch>
                <Route exact path='/servicetemplategroups/' component={LazyDrumsList} />
                { /* <Route exact path='/servicetemplategroups/auto' component={LazyDrumsList} /> */ }
                { /* <Route exact path='/servicetemplategroups/style' component={LazyDrumsList} /> */ }
                <Route exact path='/servicetemplategroups/*' component={LazyDrumsList} />
            </Switch>
        );
    }
}

export default ServiceTemplateGroups;
