import React from 'react';
import classes from './NavBarLine.module.scss';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ItemNavBarLine = ({ title }) => {
    return (
        <>
            <div className={classes.text}>
                { title ? title.toUpperCase() : '' }
            </div>
            <div className={classes.underline}/>
        </>
    );
}

ItemNavBarLine.propTypes = {
    title: PropTypes.string
};

const NavBarLine = ({ activeTitle, navBarData = [], rightItems = [] }) => {
        return (
            <div className={classes.navBarLine}>
                <div className={classes.leftItems}>
                    {
                        navBarData.map((item) => {
                            return (
                                item.link ? (
                                        <NavLink
                                            to={item.link}
                                            key={item.title + '_' + item.id}
                                            onClick={() => item.action ? item.action(item.id) : () => {}}
                                            activeClassName={classes.active}
                                            className={classes.title + (item.styleClasses || []).map(item => ' ' + classes[item]).join('')}
                                        >
                                            <ItemNavBarLine title={item.title} />
                                        </NavLink>
                                    )
                                    : (
                                        <div
                                            onClick={() => item.action ? item.action(item.id) : () => {}}
                                            key={item.title + '_' + item.id}
                                            className={classes.title + (activeTitle === item.id ? ' ' + classes.active : '') + (item.styleClasses || []).map(item => ' ' + classes[item]).join('')}
                                        >
                                            <ItemNavBarLine title={item.title} />
                                        </div>
                                    )
                            );
                        })
                    }
                </div>
                {rightItems.length > 0 ?
                    <div className={classes.rightItems}>
                        {rightItems}
                    </div>
                    : null
                }
            </div>
        );
}

NavBarLine.propTypes = {
    activeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    navBarData: PropTypes.array,
    rightItems: PropTypes.array,
};

export default NavBarLine;
