import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import 'font-awesome/css/font-awesome.min.css';
import FrontFrame from '../../components/FrontFrame';

import './CoreLayout.scss';
import '../../styles/core.scss';

const FREE_PAGES = ['/login', '/remind-password', '/create-company', '*'];

class CoreLayout extends Component {
    componentDidMount() {
        if ((!this.props.user || !this.props.token) && !(_.includes(FREE_PAGES, this.props.location.pathname))) {
            this.props.history.push('/login');
        }
    }

    componentDidUpdate() {
        if (this.props.error && this.props.error.status === 401 && !(_.includes(FREE_PAGES, this.props.location.pathname))) {
            this.props.logout();// разлогиниваемся
            this.props.clearAjaxError();// ощищаем ошибку
            this.props.history.push('/login');
        }
    }

    render() {
        const isFree = _.includes(FREE_PAGES, this.props.location.pathname);
        if ((!this.props.user || !this.props.token) && !(_.includes(FREE_PAGES, this.props.location.pathname))) {
            return null;
        }

        if (this.props.error && this.props.error.status === 403) {
            return isFree
                ? (<div className='halt-screen halt-screen--denied'><span>Нет прав доступа!</span></div>)
                : (<FrontFrame {...this.props}><div className='halt-screen halt-screen-screen'><span>Нет прав доступа</span></div></FrontFrame>);
        }
        return isFree ? (<div>{ this.props.children }</div>) : (<FrontFrame {...this.props}>{ this.props.children }</FrontFrame>);
    }
}

CoreLayout.propTypes = {
    children: PropTypes.element.isRequired,
    history: PropTypes.any,
    error: PropTypes.object,
    user: PropTypes.object,
    location: PropTypes.object.isRequired,
    token: PropTypes.string,
    clearAjaxError: PropTypes.func,
    logout: PropTypes.func
};

export default CoreLayout;
