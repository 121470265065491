import { connect } from 'react-redux';
import ReferencesLayout from './ReferencesLayout';
import { fetchReferenceGroups } from '../../routes/References/modules/references.reducer';


const mapStateToProps = (state) => ({
    referenceGroups: state.references.referenceGroups,
    fetching: state.references.fetching,
    fetchingBusinessTypes: state.companies.fetching.businessTypes,
    fetchingGroups: state.references.fetchingGroups,
    creating: state.references.creating,
    updating: state.references.updating,
});

export default connect(mapStateToProps, { fetchReferenceGroups })(ReferencesLayout);
