import { connect } from 'react-redux';
import ExpensesLayout from './ExpensesLayout';
import { fetchExpensesTypes } from '../../routes/Expenses/modules/expenses.reducer';


const mapStateToProps = (state) => ({
    expensesTypes: state.expenses.expensesTypes,
    fetching: state.expenses.fetching,
    fetchingExpenses: state.expenses.fetchingExpenses,
    fetchingExpensesType: state.expenses.fetchingExpensesType,
    fetchingBusinessTypes: state.companies.fetching.businessTypes,
    fetchingTypes: state.expenses.fetchingTypes,
    creating: state.expenses.creating,
    updating: state.expenses.updating,
});

export default connect(mapStateToProps, { fetchExpensesTypes })(ExpensesLayout);
