import { api, httpBuildQuery } from '../../../utils';

export const fetchPaidDaysLeftCount = () => {
    return function(dispatch) {
        dispatch(requestBalance());
        return api('/v3/admin/companies/paid_days_left_count', {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receivePaidDaysLeftCount(res));
                }
            });
    };
};

export const REQUEST_BALANCE = 'requestBalance';
function requestBalance() {
    return {
        type: REQUEST_BALANCE
    };
}

export const RECEIVE_PAID_DAYS_LEFT_COUNT = 'receivePaidDaysLeftCount';
function receivePaidDaysLeftCount(res) {
    return {
        type: RECEIVE_PAID_DAYS_LEFT_COUNT,
        payload: res
    };
}

export const fetchCompaniesBySearch = query => {
    if (query.search === '') {
        return dispatch => dispatch(receiveCompaniesBySearch(null));
    }

    return dispatch => {
        dispatch(requestCompaniesBySearch());
        return api(
            '/v3/admin/companies/search?' + httpBuildQuery(query), {
                method: 'GET'
            },
            dispatch
        ).then(
            res => {
                return dispatch(receiveCompaniesBySearch(res));
            }
        );
    };
};

export const REQUEST_COMPANIES_BY_SEARCH = 'requestCompaniesBySearch';
const requestCompaniesBySearch = () => {
    return {
        type: REQUEST_COMPANIES_BY_SEARCH
    };
};

export const RECEIVE_COMPANIES_BY_SEARCH = 'receiveCompaniesBySearch';
const receiveCompaniesBySearch = res => {
    return {
        type: RECEIVE_COMPANIES_BY_SEARCH,
        payload: {
            res
        }
    };
};

export const fetchCompaniesByPaidDaysLeftCount = query => {
    return function(dispatch) {
        dispatch(requestCompaniesByPaidDaysLeftCount(query.type));
        return api('/v3/admin/companies/grouped_days_left?' + httpBuildQuery(query), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receiveCompaniesByPaidDaysLeftCount(res, query.type));
                }
            });
    };
};

export const fetchBalanceHistory = data => {
    return function(dispatch) {
        dispatch(requestBalanceHistory(data));
        // /v4/admin/payments?expand=tx.company&company_name=<string>&limit=<int>&offset=<int>
        return api('/v4/admin/payments/?' + httpBuildQuery(data), {
            method: 'GET'
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receiveBalanceHistory(res));
                }
            });
    };
};

export const REQUEST_COMPANIES_BY_PAID_DAYS_LEFT_COUNT = 'requestCompaniesByPaidDaysLeftCount';
function requestCompaniesByPaidDaysLeftCount(type) {
    return {
        type: REQUEST_COMPANIES_BY_PAID_DAYS_LEFT_COUNT,
        payload: type
    };
}
export const REQUEST_BALANCE_HISTORY = 'requestBalanceHistory';
function requestBalanceHistory(data) {
    return {
        type: REQUEST_BALANCE_HISTORY,
        payload: data
    };
}

export const RECEIVE_COMPANIES_BY_PAID_DAYS_LEFT_COUNT = 'receiveCompaniesByPaidDaysLeftCount';
function receiveCompaniesByPaidDaysLeftCount(res, type) {
    return {
        type: RECEIVE_COMPANIES_BY_PAID_DAYS_LEFT_COUNT,
        payload: {
            res: res,
            type: type
        }
    };
}

export const RECEIVE_BALANCE_HISTORY = 'receiveBalanceHistory';
function receiveBalanceHistory(res) {
    return {
        type: RECEIVE_BALANCE_HISTORY,
        payload: {
            res: res
        }
    };
}

export const CHANGE_COMPANY_TYPE_PAGE = 'changeCompanyTypePage';
export const changeCompanyTypePage = (type, page) => {
    return {
        type: CHANGE_COMPANY_TYPE_PAGE,
        payload: {
            type: type,
            page: page
        }
    };
};

export const rechargeCompanyBalance = (data, type) => {
    return function(dispatch) {
        dispatch(requestBalance());
        return api('/v3/admin/payments/recharge', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res) {
                    return dispatch(receiveRechargedCompanyBalance(res, type));
                }
            });
    };
};

export const RECEIVE_RECHARGED_COMPANY_BALANCE = 'receiveRechargedCompanyBalance';
function receiveRechargedCompanyBalance(res, type) {
    return {
        type: RECEIVE_RECHARGED_COMPANY_BALANCE,
        payload: {
            res: res,
            type: type
        }
    };
}
