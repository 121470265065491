import React, { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

const LazyServices = lazy(() => import('./modules/Services.container'));

class Services extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        return (
            <Switch>
                <Route exact path='/services' component={LazyServices} />
                <Route path='/services'>
                    <Switch>
                        <Route exact path='/services' render={props => <LazyServices {...props} />} />
                    </Switch>
                </Route>
            </Switch>
        );
    }
};

export default Services;
