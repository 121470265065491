import React, { lazy, Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import balanceReduser from './module/Balance.reducer';
const LazyBalance = lazy(() => import('./module/Balance.container'));

class Balance extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('balance', balanceReduser);
        return (
            <Route exact path='/balance' render={props => <LazyBalance />} />
        );
    }
}

export default Balance;
