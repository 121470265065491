import classes from './CompanyInfoString.module.scss';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { phoneFormatToString } from '../../../../utils/phoneFormat';

const fieldsFakeCompany = [
    { title: 'Телефон:', name: 'phone', type: 'phone' },
    { title: 'Id:', name: 'id', type: 'string' },
    { title: 'Создан:', name: 'createdAt', type: 'time' }
];

const Field = ({ title, type, value }) => {
    let valueNode;
    if (type === 'phone') {
        valueNode = (<div className={classes.fieldValue}>{ value ? phoneFormatToString(value) : '------------' }</div>);
    }
    if (type === 'string') {
        valueNode = (<div className={classes.fieldValue}>{ value || '------------' }</div>);
    }

    if (type === 'time') {
        valueNode = (<div className={classes.fieldValue}>{ value ? format(new Date(value), 'dd.MM.yyyy') : '------------' }</div>);
    }

    if (type === 'bool') {
        valueNode = (<div className={classes.fieldValue}>{ value ? <div className={classes.isActive} /> : 'x' }</div>);
    }

    return (
        <div className={classes.fieldWrap}>
            <div className={classes.fieldName}>{ title }</div>
            { valueNode }
        </div>
    );
}

Field.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

export const CompanyInfoString = ({ data, fields = fieldsFakeCompany }) => {
    return (
        <div className={classes.fieldsWrap}>
            {
                fields.length > 0 && fields.map((field) => {
                    return <Field key={'Field_' + field.name} title={field.title} type={field.type} value={data[field.name]} />
                })
            }
        </div>
    );
}

CompanyInfoString.propTypes = {
    fields: PropTypes.array,
    data: PropTypes.object
};

export default CompanyInfoString;
