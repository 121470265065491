import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './Login.module.scss';
import { Link } from 'react-router-dom';
import InputElement from 'react-input-mask';

import Button from '../../../components/Button';

class Login extends Component {
    state = {
        showPass: false
    }

    componentDidMount() {
        if (this.props.user && this.props.token) {
            this.props.history.push('/');
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.user && nextProps.token) {
    //         this.props.history.push('/');
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user !== this.props.user && prevProps.token !== this.props.token) {
            this.props.history.push('/');
        }
    }

    handleChangeValue = (e) => {
        this.props.changeValue(e);
    };

    showHidePassword = (e) => {
        const target = e.target;
        const input = document.getElementById('password-input');
        if (input.getAttribute('type') === 'password') {
            target.classList.add('view');
            input.setAttribute('type', 'text');
            this.setState({ showPass: true });
        } else {
            target.classList.remove('view');
            input.setAttribute('type', 'password');
            this.setState({ showPass: false });
        }
        return false;
    }

    render() {
        let loginLength = this.props.login;
        loginLength = parseInt(loginLength.replace(/_/g, '').length);
        return (
            <>
                <div>
                    <div className={classes.mainLabel}>Control 2.0</div>
                    <div className={classes.secondLabel}>Контрольная панель проекта ORTUS</div>
                </div>
                <div className={classes.mainContainer}>
                    <div>
                        { /* <div className={classes.typeLoginPassLabel}>ввести логин и пароль</div> */ }
                        <div className={` ${classes.copImage} ${this.props.error ? classes.error : ''} ${loginLength === 18 && this.props.password.length !== 0 ? classes.full : ''}`} />
                        <div className={classes.loginContainer}>
                            <div className={classes.errorLabel}>
                                { this.props.error ? 'Неверный логин или пароль' : null }
                            </div>
                            <InputElement
                                type='text'
                                name='login'
                                placeholder='+7 (___) ___-__-__'
                                value={this.props.login}
                                onChange={this.handleChangeValue}
                                mask='+7 (999) 999-99-99'
                                className={`${classes.loginBox} ${this.props.error ? classes.error : ''} ${loginLength === 18 ? classes.full : ''}`}
                                onKeyPress={
                                    e => {
                                        return e.key === 'Enter' ? this.props.submit(e) : null;
                                    }
                                }
                            />
                            <div className={classes.inputContainer}>
                                <InputElement
                                    className={` ${classes.loginBox} ${this.props.error ? classes.error : ''} ${this.props.password.length !== 0 ? classes.full : ''}`}
                                    type='password'
                                    id='password-input'
                                    name='password'
                                    placeholder='введите пароль'
                                    value={this.props.password}
                                    onChange={this.handleChangeValue}
                                    onKeyPress={
                                        e => {
                                            return e.key === 'Enter' ? this.props.submit(e) : null;
                                        }
                                    }
                                />
                                <button
                                    onClick={(e) => this.showHidePassword(e)}
                                    disabled={this.props.password.length === 0}
                                    className={`${classes.passwordControl} ${this.state.showPass ? classes.view : ''}`}
                                />
                            </div>
                            <Link className={classes.forgot} to='/remind-password'>напомнить</Link>
                        </div>
                        <Button
                            className={classes.submit} onClick={
                                e => {
                                    this.props.submit(e);
                                }
                            }
                            disabled={loginLength !== 18 || this.props.password.length === 0}
                        >Войти
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

Login.propTypes = {
    login: PropTypes.string,
    password: PropTypes.string.isRequired,
    user: PropTypes.object,
    history: PropTypes.object,
    error: PropTypes.bool.isRequired,
    token: PropTypes.string,
    changeValue: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
};

export default Login;
