import _ from 'lodash';

import {
    CHANGE_COMPANY_TYPE_PAGE,
    RECEIVE_COMPANIES_BY_PAID_DAYS_LEFT_COUNT,
    RECEIVE_COMPANIES_BY_SEARCH,
    RECEIVE_PAID_DAYS_LEFT_COUNT,
    RECEIVE_RECHARGED_COMPANY_BALANCE,
    REQUEST_BALANCE,
    REQUEST_COMPANIES_BY_PAID_DAYS_LEFT_COUNT,
    REQUEST_COMPANIES_BY_SEARCH,
    REQUEST_BALANCE_HISTORY,
    RECEIVE_BALANCE_HISTORY
} from './Balance.actions';

const ACTION_HANDLERS = {
    [REQUEST_BALANCE]: (state) => {
        return ({...state, fetching: true});
    },
    [REQUEST_BALANCE_HISTORY]: (state) => {
        return ({...state, fetching: true});
    },
    [RECEIVE_PAID_DAYS_LEFT_COUNT]: (state, action) => {
        return ({...state, paidDaysLeftCount: action.payload, companies: {}, fetching: false});
    },
    [REQUEST_COMPANIES_BY_PAID_DAYS_LEFT_COUNT]: (state, action) => {
        const fetchingCompanies = Object.assign({}, state.fetchingCompanies);
        fetchingCompanies[action.payload] = true;

        return ({...state, fetchingCompanies: fetchingCompanies});
    },
    [RECEIVE_COMPANIES_BY_PAID_DAYS_LEFT_COUNT]: (state, action) => {
        const companies = Object.assign({}, state.companies);
        const fetchingCompanies = Object.assign({}, state.fetchingCompanies);

        companies[action.payload.type] = action.payload.res;
        fetchingCompanies[action.payload.type] = false;

        return ({...state, companies: companies, fetchingCompanies: fetchingCompanies});
    },
    [CHANGE_COMPANY_TYPE_PAGE]: (state, action) => {
        const companyTypesPages = Object.assign({}, state.companyTypesPages);

        companyTypesPages[action.payload.type] = action.payload.page;

        return ({...state, companyTypesPages: companyTypesPages});
    },
    [RECEIVE_RECHARGED_COMPANY_BALANCE]: (state, action) => {
        const companies = _.cloneDeep(state.companies);
        const searchedCompanies = _.cloneDeep(state.searchedCompanies);

        if (companies[action.payload.type]) {
            const companyKey = _.findKey(companies[action.payload.type], ['id', action.payload.res.company.id]);
            companies[action.payload.type][companyKey].balance = parseFloat((companies[action.payload.type][companyKey].balance + action.payload.res.amount).toFixed(2));
        } else {
            searchedCompanies.map(company => {
                if (action.payload.res.company.id === company.id) {
                    company.balance = parseFloat((company.balance + action.payload.res.amount).toFixed(2));
                }
                return company;
            });
            for (const type in companies) {
                if (Object.prototype.hasOwnProperty.call(companies, type)) {
                    companies[type] = companies[type].map(company => {
                        if (action.payload.res.company.id === company.id) {
                            company.balance = parseFloat((company.balance + action.payload.res.amount).toFixed(2));
                        }
                        return company;
                    });
                }
            }
        }

        return ({...state, companies, fetching: false, searchedCompanies});
    },
    [REQUEST_COMPANIES_BY_SEARCH]: state => {
        return {...state, fetching: true};
    },
    [RECEIVE_COMPANIES_BY_SEARCH]: (state, action) => {
        return {...state, fetching: false, searchedCompanies: action.payload.res};
    },
    [RECEIVE_BALANCE_HISTORY]: (state, action) => {
        return {...state, fetching: false, balanceHistory: action.payload.res};
    }
};

// ------------------------------------<
// Reducer
// ------------------------------------
const initialState = {
    companies: {},
    companyTypesPages: {},
    paidDaysLeftCount: null,
    fetching: false,
    fetchingCompanies: {},
    searchedCompanies: null,
    balanceHistory: null
};

export default function instructionsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
