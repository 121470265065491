// import { connectRouter } from 'connected-react-router';
import account from '../reducers/auth';
import companies from '../reducers/companies';
import catalogues from '../reducers/catalogues';
import users from '../routes/Users/modules/Users.reducer';
import roles from '../reducers/roles';
import orders from '../reducers/orders';
import hangar from '../reducers/hangar';
import statistics from '../reducers/statistics';
import remindPassword from '../routes/RemindPassword/modules/remindPassword';
import media from '../reducers/media';
import communicationEvents from '../routes/CommunicationEvents/modules/communicationEvents';

export const makeRootReducer = (history, asyncReducers) => ({
    // router: connectRouter(history),
    account,
    companies,
    catalogues,
    users,
    roles,
    orders,
    hangar,
    statistics,
    media,
    remindPassword,
    communicationEvents,
    ...asyncReducers
});

export default makeRootReducer;
