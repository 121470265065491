import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PreLoader from '../../components/PreLoader/PreLoader';

const ExpensesLayout = ({
    children,
    expensesTypes,
    fetching,
    fetchingExpenses,
    fetchingExpensesType,
    fetchExpensesTypes = () => {},
}) => {
    useEffect(() => {
        if (expensesTypes == null) {
            fetchExpensesTypes();
        }
    }, [expensesTypes, fetchExpensesTypes]);

    return (
        <div>
            {children}
            <PreLoader trigger={fetching || fetchingExpenses || fetchingExpensesType} />
        </div>
    );
}


ExpensesLayout.propTypes = {
    fetching: PropTypes.bool,
    expensesTypes: PropTypes.array,
    fetchExpensesTypes: PropTypes.func,
    children: PropTypes.node,
    fetchingExpenses: PropTypes.bool,
    fetchingExpensesType: PropTypes.bool,
};

export default ExpensesLayout;
