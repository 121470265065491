import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import logo from './assets/connect-pro_logo.svg';
import styles from './Header.module.scss';

export class Header extends React.Component {

    state = {
        width: 0,
        height: 0,
        smallSize: false,
        logOutButtons: false
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleLogOut = (e) => {
        e.preventDefault();
        this.props.logout();
        this.props.history.push('/login');
    }

    isActivePage = (path) => {
        return window.location.pathname === path;
    }

    goSettings = () => {
        this.props.history.push(`'/clients/${this.props.user.client_id}/user'`);
    }

    render() {
        const size = 1461;
        const allSmall = this.state.width < size;
        return (
            <div className={`${styles.header} ${allSmall ? styles.small : ''}`}>
                <div className={`${styles.headerLayout} ${allSmall ? styles.small : ''}`}>
                    <div
                        className={`${styles.appName} ${allSmall ? styles.small : ''}`} onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push('/');
                        }}
                    >Connect Pro <img src={logo} alt='лого' /> Control
                    </div>
                    <div className={`${styles.navigation} ${allSmall ? styles.small : ''}`} onClick={() => this.props.clearAjaxError()}>
                        <NavLink
                            to='/catalogues'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.catservActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.catserv)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Каталог услуг</span>
                        </NavLink>
                        <NavLink
                            to='/services'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.servActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.serv)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Услуги</span>
                        </NavLink>
                        <NavLink
                            to='/servicetemplategroups/'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.drumsActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.drums)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Топ-Услуги</span>
                        </NavLink>
                        <NavLink
                            to='/packets'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.packetsActive)}`}
                            className={`${allSmall ? (styles.navListSmall) : (styles.icon + ' ' + styles.navList + ' ' + styles.packets)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Настраиваемые {allSmall ? '' : 'услуги'}</span>
                        </NavLink>
                        <NavLink
                            to='/expenses-types'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.expensesActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.expenses)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Расходы</span>
                        </NavLink>
                        <NavLink
                            to='/companies'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.companiesActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.companies)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Компании</span>
                        </NavLink>
                        <NavLink
                            to='/users'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.usersActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.users)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Пользователи</span>
                        </NavLink>
                        <NavLink
                            to='/clients'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.clientsActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.clients)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Клиенты</span>
                        </NavLink>
                        <NavLink
                            to='/communication-events'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.notificationsActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.notifications)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Уведомления</span>
                        </NavLink>
                        <NavLink
                            to='/'
                            exact
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.statisticActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.statistic)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Статистика</span>
                        </NavLink>
                        <NavLink
                            to='/metrics'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.metricsActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.metrics)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Метрики</span>
                        </NavLink>
                        <NavLink
                            to='/mailing'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.sendActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.send)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>ORTUS Рассылка</span>
                        </NavLink>
                        <NavLink
                            to='/balance'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.balanceActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.balance)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Баланс</span>
                        </NavLink>
                        <NavLink
                            to='/instructions'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.instructionsActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.instructions)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Инструкции</span>
                        </NavLink>
                        <NavLink
                            to='/references'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.referencesActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.references)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Справочники</span>
                        </NavLink>
                        <NavLink
                            to='/car-gallery'
                            activeClassName={`${allSmall ? styles.navActive : (styles.navActive + ' ' + styles.icon + ' ' + styles.carGalleryActive)}`}
                            className={`${allSmall ? (styles.navListSmall + ' ' + styles.catserv) : (styles.icon + ' ' + styles.navList + ' ' + styles.carGallery)}`}
                        >
                            <span className={`${styles.navLabel} ${allSmall ? styles.small : ''}`}>Авто галерея</span>
                        </NavLink>
                    </div>
                    { this.state.logOutButtons ? (
                        <div className={`${allSmall ? styles.buttonsContainerSmall : styles.buttonsContainer}`}>
                            { <button className={styles.logout} onClick={this.handleLogOut}>Выйти</button> }
                            { <NavLink to={`/clients/${this.props.user.client_id}/user`} className={styles.logNav}><button className={styles.logoutSet}>Настроить</button></NavLink> }
                        </div>
                    ) : null }
                    <div className={`${allSmall ? styles.userNavSmall : styles.userNav}`}>
                        <div className={styles.blackBack} onClick={() => this.setState({ logOutButtons: !this.state.logOutButtons })}>
                            <div className={`${styles.avatar} ${allSmall ? styles.small : ''}`} />
                            <div>{ this.props.user.name }</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Header.propTypes = {
    logout: PropTypes.func,
    user: PropTypes.object,
    history: PropTypes.object
};

export default Header;
