import { CommonInfoBody, HeaderInMainBody } from '../../../../templates';
import CompanyInfoString from '../CompanyInfoString';
import NavBarLine from '../../../../components/NavBarLine';

const fieldsCompany = [
    { title: 'Телефон:', name: 'phone', type: 'phone' },
    { title: 'Id:', name: 'id', type: 'string' },
    { title: 'Создан:', name: 'createdAt', type: 'time' },
    { title: 'Ortus', name: 'isActive', type: 'bool' }
];

const Company = ({ children, company }) => {
    const phone = company?.fields.find(item => item.label === 'company_phones')?.value?.find(item => item.label === 'Основной')?.value;
    return (
        <CommonInfoBody
            body={children}
            header={
                <HeaderInMainBody
                    title={company?.name}
                    breadcrumbs={[{ title: 'Компании', link: '/companies' }]}
                    label={ company?.is_fake ? 'Без собственника' : ''}
                    description={
                        <CompanyInfoString
                            fields={fieldsCompany}
                            data={{
                                id: company?.id,
                                phone: phone,
                                createdAt: company?.angaraInfo?.createdAt || null,
                                isActive: company?.angaraInfo?.isActive
                            }}
                        />
                    }
                />
            }
            navbar={
                <NavBarLine
                    navBarData={company?.is_fake ? [
                        { title:'Основная информация', link: `/companies/${company.id}/info`, action: () => {}, id: 1, styleClasses: [] },
                        { title:'Услуги', link: `/companies/${company.id}/services`, action: () => {}, id: 2, styleClasses: [] }
                    ] : [
                        { title:'Основная информация', link: `/companies/${company.id}/info` },
                        { title:'Ресурсы', link: `/companies/${company.id}/resources` },
                        { title:'Клиенты', link: `/companies/${company.id}/clients` },
                        { title:'Заказы', link: `/companies/${company.id}/orders` },
                        { title:'Услуги', link: `/companies/${company.id}/services` }
                    ]}
                    activeTitle={1}
                />
            }
        />
    )
}

export default Company;
