import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from '../../reducers/hangar';
import { logout } from '../../reducers/auth';

import CoreLayout from './CoreLayout';

const mapStateToProps = (state) => ({
    error: state.hangar.ajaxError,
    user: state.account.user,
    token: state.account.token
});

export default withRouter(connect(mapStateToProps, Object.assign(actions, { logout }))(CoreLayout));
