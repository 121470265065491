import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classes from './navbar.module.scss';

class NavBar extends React.Component {
    render() {
        const client = this.props.client;
        if (client) {
            return (
                <div className={classes.navigation}>
                    <NavLink
                        activeClassName={classes.active} className={classes.inactive}
                        to={'/clients/' + this.props.client.id + '/orders'}
                    >
                        <div>Заказы/Резервы</div>
                    </NavLink>
                    <NavLink
                        activeClassName={classes.active} className={classes.inactive}
                        to={'/clients/' + this.props.client.id + '/cars'}
                    >
                        <div>Автомобили</div>
                    </NavLink>
                    <NavLink
                        activeClassName={classes.active} className={classes.inactive}
                        to={'/clients/' + this.props.client.id + '/okk-notifications'}
                    >
                        <div>Окк</div>
                    </NavLink>
                    <NavLink
                        activeClassName={classes.active} className={classes.inactive}
                        to={'/clients/' + this.props.client.id + '/user'}
                    >
                        <div>Пользователь</div>
                    </NavLink>
                    <NavLink
                        activeClassName={classes.active} className={classes.inactive}
                        to={'/clients/' + this.props.client.id + '/companies'}
                    >
                        <div>Компании</div>
                    </NavLink>
                    <NavLink
                        activeClassName={classes.active} className={classes.inactive}
                        to={'/clients/' + this.props.client.id + '/notifications'}
                    >
                        <div>Уведомления</div>
                    </NavLink>
                </div>
            );
        } else return null;
    }
}

NavBar.propTypes = {
    client: PropTypes.object
};

const mapStateToProps = (state) => ({
    client: state.clients.client
});

export default withRouter(connect(mapStateToProps)(NavBar));
