import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';

import classes from './frontFrame.module.scss';
document.body.style = 'background: #F2F1ED';
class FrontFrame extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        logout: PropTypes.func.isRequired
    };

    state = {};

    logOut(e) {
        e.preventDefault();
        this.props.logout();
        this.context.router.push('/login');
    }

    render() {
        return (
            <div className={classes.container}>
                <Header />
                <div className={classes.content}>
                    { this.props.children }
                </div>
            </div>);
    }
}

export default FrontFrame;
