import React, { lazy, Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import MetricsReducer from './modules/metrics.reducer';
import PropTypes from 'prop-types';
import MetricsLayout from '../../layouts/MetricsLayout';

const LazyMetrics = lazy(() => import('./modules/Metrics.container'));

class Metrics extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    render() {
        this.props.store.injectReducer('metrics', MetricsReducer);
        return (
            <Switch>
                <Route path='/metrics'>
                    <MetricsLayout>
                        <Route exact path='/metrics' component={LazyMetrics} />
                    </MetricsLayout>
                </Route>
            </Switch>
        );
    }
}

export default Metrics;
