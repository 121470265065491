import { api, httpBuildQuery } from './../utils';

import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const ON_ORDER = 'orderMode';
const CHOOSE_TO_ORDER = 'chooseToOrder';
const SEND_ORDER = 'order';
const FINISH_ORDER = 'finishOrder';
const CLEAR_ORDER = 'clearOrder';
const CONFIRM_CANCEL_ORDER = 'confirmCancelOrder';
const CANCEL_ORDER = 'cancelOrder';
const EXECUTE_ORDER = 'executeOrder';

const REQUEST_ORDERS = 'requestOrders';
const RECEIVE_ORDERS = 'receiveOrders';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCancelOrder = (id, e) => {
    if (e) {
        e.preventDefault();
    }
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v1/partner/orders/' + id, {
            method: 'PUT',
            body: JSON.stringify({ status: 'CANCELED' })
        }, dispatch)
            .then(() => dispatch(cancelOrder(id)));
    };
};

function cancelOrder(id) {
    return {
        type: CANCEL_ORDER,
        id: id
    };
}

export const fetchExecuteOrder = (id, e) => {
    if (e) {
        e.preventDefault();
    }
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v1/partner/orders/' + id, {
            method: 'PUT',
            body: JSON.stringify({ status: 'EXECUTED' })
        }, dispatch)
            .then(() => dispatch(executeOrder(id)));
    };
};

function executeOrder(id) {
    return {
        type: EXECUTE_ORDER,
        id: id
    };
}

export const toConfirmCancelOrder = (e) => {
    if (e) {
        e.preventDefault();
    }
    return {
        type: CONFIRM_CANCEL_ORDER
    };
};

export const ordering = (mode) => {
    return {
        type: ON_ORDER,
        mode: mode
    };
};

export const fetchOrders = (companyId, data, merge = false) => {
    return function(dispatch) {
        dispatch(requestOrders());
        return api('/v1/partner/orders/company/' + companyId + '?' + httpBuildQuery(data, '', '&'), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveOrders(response, merge)));
    };
};

function requestOrders() {
    return {
        type: REQUEST_ORDERS
    };
}

function receiveOrders(data, merge) {
    return {
        type: RECEIVE_ORDERS,
        merge: merge,
        data: data
    };
}

export const postOrder = (clientId, data) => {
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v1/partner/orders/' + clientId, {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(response => dispatch(finishOrder(response)));
    };
};

export const updateOrderDetails = (data) => {
    return function(dispatch) {
        dispatch(sendOrder());
        return api('/v1/partner/orders/details/' + data.id, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: { 'X-Api-Token': localStorage.getItem('token') }
        }, dispatch)
            .then(response => dispatch(finishOrder(response)));
    };
};

export const chooseToOrder = (data, e) => {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    return {
        type: CHOOSE_TO_ORDER,
        data: data
    };
};

export const sendOrder = () => {
    return {
        type: SEND_ORDER
    };
};

export const finishOrder = () => {
    return {
        type: FINISH_ORDER
    };
};

export const clearOrder = () => {
    return {
        type: CLEAR_ORDER
    };
};

export const actions = {
    fetchOrders,
    clearOrder,
    chooseToOrder,
    postOrder,
    updateOrderDetails,
    ordering,
    toConfirmCancelOrder,
    fetchCancelOrder,
    fetchExecuteOrder
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [ON_ORDER]: (state, action) => {
        return ({
            ...state,
            orderMode: state.orderMode ? null
                : (action.mode ? action.mode : 'onOrder')
        });
    },
    [CHOOSE_TO_ORDER]: (state, action) => {
        let toOrder = Object.assign({}, state.toOrder);
        toOrder = action.data ? _.merge(toOrder, action.data) : null;
        return ({ ...state, toOrder: toOrder });
    },
    [SEND_ORDER]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.sendOrder = true;
        return ({ ...state, fetching });
    },
    [FINISH_ORDER]: (state) => {
        const fetching = Object.assign({}, state.fetching); const toOrder = Object.assign({}, state.toOrder);
        fetching.sendOrder = false;
        toOrder.status = 'finish';
        return ({ ...state, fetching, toOrder });
    },
    [CLEAR_ORDER]: (state) => {
        return ({ ...state, toOrder: null });
    },
    [REQUEST_ORDERS]: (state) => {
        const fetching = Object.assign({}, state.fetching);
        fetching.orders = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_ORDERS]: (state, action) => {
        let orders;
        const fetching = Object.assign({}, state.fetching);
        fetching.orders = false;
        if (action.merge) {
            orders = Object.assign({}, state.orders);
            _.map(action.data.orders, order => {
                if (!_.some(orders.orders, { id: order.id })) {
                    orders.orders.push(order);
                } else {
                    orders.orders[_.findKey(orders.orders, { id: order.id })] = order;
                }
            });
        } else {
            orders = action.data;
        }
        return ({ ...state, orders: orders, fetching: fetching });
    },
    [CONFIRM_CANCEL_ORDER]: (state) => {
        return ({ ...state, confirmCancelOrder: !state.confirmCancelOrder });
    },
    [CANCEL_ORDER]: (state, action) => {
        const orders = Object.assign({}, state.orders);
        const fetching = Object.assign({}, state.fetching);
        fetching.sendOrder = false;
        orders.orders = _.filter(orders.orders, order => {
            return order.id !== action.id;
        });
        return ({ ...state, confirmCancelOrder: false, orders: orders, fetching: fetching });
    },
    [EXECUTE_ORDER]: (state, action) => {
        const orders = Object.assign({}, state.orders);
        _.some(orders.orders, order => {
            if (order.id === action.id) {
                order.status = 'EXECUTED';
            }
            return order.id === action.id;
        });
        return ({ ...state, orders: orders });
    }
};

const initialState = {
    fetching: { sendOrder: false },
    orderMode: false,
    toOrder: null,
    orders: {},
    confirmCancelOrder: false
};

export default function ordersReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
