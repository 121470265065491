import React from 'react';
import { NavLink } from 'react-router-dom';
import arrow from './assets/arrowRight.svg';
import style from './BreadCrumbs.module.scss';

const BreadCrumbs = ({ breadcrumbs = [], classes = [] }) => {
    return (
        breadcrumbs && breadcrumbs.length > 0
            ? (
                <div className={style.breadcrumbs + classes.map(item => ' ' + style[item]).join('')}>
                    {
                        breadcrumbs.map(item => {
                            return (
                                <NavLink to={item.link} className={style.link} key={item.link}>
                                    <div className={style.title}>{ item.title }</div>
                                    <div>
                                        <img src={arrow} alt='стрелка' className={style.img} />
                                    </div>
                                </NavLink>
                            );
                        })
                    }
                </div>
            )
            : null
    );
}

export default BreadCrumbs;
