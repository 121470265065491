import React from 'react';
import { Route, Switch } from 'react-router-dom';

const LazyStatistic = React.lazy(() => import('./Components/Statistic'));
const LazyStatisticGraph = React.lazy(() => import('./Components/StatisticGraph'));

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (
        <Switch>
            <Route exact path='/' component={LazyStatistic} />
            <Route exact path='/statistic/:id' component={LazyStatisticGraph} />
        </Switch>
    );
};
