import { api, httpBuildQuery } from '../utils';

import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_IMAGES = 'requestImages';
const RECEIVE_IMAGES = 'receiveImages';

const PUSH_MEDIA_FILE = 'pushMediaFile';

const REQUEST_SVG_IMAGES = 'requestSvgImages';
const RECEIVE_SVG_IMAGES = 'receiveSvgImages';

const PUSH_SVG_FILE = 'pushSvgFile';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchImages = (data) => {
    data.expand = 'thumbnails';
    return function(dispatch) {
        dispatch(requestImages());
        return api('/v1/admin/media' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveImages(response)));
    };
};

export const fetchSvgImages = (data) => {
    data.expand = 'thumbnails';
    return function(dispatch) {
        dispatch(requestSvgImages());
        return api('/v4/admin/svg-images' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), {
            method: 'GET'
        }, dispatch)
            .then(response => dispatch(receiveSvgImages(response)));
    };
};

export const addImages = (data) => {
    return function(dispatch) {
        dispatch(requestImages());
        return api('/v1/admin/media?expand=thumbnails', {
            method: 'POST',
            body: data
        }
        , dispatch, true)
            .then(response => dispatch(receiveImages(response)));
    };
};
export const addSvgImages = (file) => {
    return function(dispatch) {
        dispatch(requestSvgImages());
        return api('/v4/admin/svg-images', {
            method: 'POST',
            body: file
        }
        , dispatch, true)
            .then(response => dispatch(pushSvgFile(response)));
    };
};

export const requestImages = () => {
    return {
        type: REQUEST_IMAGES
    };
};

export const requestSvgImages = () => {
    return {
        type: REQUEST_SVG_IMAGES
    };
};

function receiveImages(response) {
    return {
        type: RECEIVE_IMAGES,
        payload: response
    };
}

function receiveSvgImages(response) {
    return {
        type: RECEIVE_SVG_IMAGES,
        payload: response
    };
}

export const pushMediaFile = (file) => {
    return function(dispatch) {
        dispatch({ type: PUSH_MEDIA_FILE, file: file });
    };
};

export const pushSvgFile = (file) => {
    return function(dispatch) {
        dispatch({ type: PUSH_SVG_FILE, file: file });
    };
};

export const actions = {
    fetchImages,
    pushMediaFile,
    fetchSvgImages,
    pushSvgFile,
    addSvgImages,
    addImages
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_IMAGES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_IMAGES]: (state, action) => {
        return ({ ...state, fetching: false, images: action.payload });
    },
    [REQUEST_SVG_IMAGES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_SVG_IMAGES]: (state, action) => {
        return ({ ...state, fetching: false, svgImages: action.payload });
    },
    [PUSH_MEDIA_FILE]: (state, action) => {
        let files = _.cloneDeep(state.images);
        if (files.items) {
            files.items.push(action.file);
        } else {
            files = { items: [action.file], total: 1 };
        }
        return ({ ...state, images: files, fetching: true });
    },
    [PUSH_SVG_FILE]: (state, action) => {
        const newSvgArr = _.cloneDeep(state.svgImages);
        newSvgArr.svgImages.push(action.file);
        return ({ ...state, svgImages: newSvgArr, fetching: true });
    }
};

const initialState = {
    fetching: false,
    images: {},
    svgImages: {}
};

export default function imagesReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
