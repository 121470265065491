import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
// import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import makeRootReducer from './reducers';

export const history = createBrowserHistory();

const staticReducers = makeRootReducer(history);

export default function configureStore(initialState) {
    const store = createStore(
        createReducer(),
        initialState,
        compose(
            applyMiddleware(
                // routerMiddleware(history),
                thunk
            )
        )
    );

    store.asyncReducers = {};

    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    return store;
}

function createReducer(asyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    });
}
