import { api } from '../utils';

// ------------------------------------
// Constants
// ------------------------------------
const CHANGE_VALUE = 'changeValue';
const SET_USER = 'setUser';
const SET_ERROR = 'setError';
const LOGOUT = 'logout';

// ------------------------------------
// Actions
// ------------------------------------

export const setUser = (tokenAndUser) => {
    return {
        type: SET_USER,
        payload: tokenAndUser
    };
};

export const submit = (e) => {
    e.preventDefault();
    return (dispatch, getState) => {
        const state = getState().account;
        return api('/admin/login', {
            method: 'post',
            body: JSON.stringify({ phone: state.login.trim(), password: state.password.trim() })
        }, dispatch)
            .then(res => {
                if (res && res.token) {
                    dispatch(setUser(res));
                }
            });
    };
};

export const changeValue = (e) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: e.target.name,
            value: e.target.value
        }
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const actions = {
    changeValue,
    setUser,
    submit,
    logout
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, error: false, [action.payload.name]: action.payload.value });
    },
    [SET_USER]: (state, action) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        return ({ ...state, user: action.payload.user, token: action.payload.token });
    },
    [LOGOUT]: (state) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return ({ ...state, user: null, token: null });
    },
    [SET_ERROR]: (state) => {
        return ({ ...state, error: true });
    }
};

const user = window.localStorage.getItem('user');
const token = window.localStorage.getItem('token');
const initialState = {
    login: '',
    password: '',
    error: false
};

if (user && token) {
    Object.assign(initialState, { user: JSON.parse(user), token: token });
}

export default function loginReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
