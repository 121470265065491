import _ from 'lodash';
import qs from 'qs';
import 'whatwg-fetch';
import { setAjaxError } from '../reducers/hangar';
import moment from 'moment';
import classes from '../routes/CommunicationEvents/communicationEvents.module.scss';
import React from 'react';

export const HELP_CSS = {
    hlpShadow: 'hlpShadow',
    hlpShadowBottom: 'hlpShadowBottom',
    hlpShadowTop: 'hlpShadowTop',
    hlpBgrWhite: 'hlpBgrWhite',
    hlpPaddingBottom: 'hlpPaddingBottom',
    hlpPaddingTop: 'hlpPaddingTop',
    hlpMarginBottom: 'hlpMarginBottom',
    hlpMarginTop: 'hlpMarginTop',
    hlpSidePadding: 'hlpSidePadding',
    hlpPadding: 'hlpPadding',
    hlpEqPadding: 'hlpEqPadding',
    hlpPanelBody: 'hlpPanelBody',
    hlpTARight: 'hlpTARight',
    hlpTACenter: 'hlpTACenter'
};

export const BUSINESS_TYPES = [
    { title: 'Авто', id: 1 },
    { title: 'Стиль', id: 2 },
    { title: 'Общепит', id: 3 },
    { title: 'Ремонт ПК', id: 5 },
    { title: 'Фотостудии', id: 4 }

];

export const api = (uri, data, dispatch, fileUpload = false, fileDownload = false) => {
    let opts = {
        method: 'get',
        mode: 'cors',
        headers: {
            Accept: 'application/json'
        }
    };
    if (!fileUpload) {
        opts.headers['Content-Type'] = 'application/json';
    }
    const token = localStorage.getItem('token');
    if (token) {
        opts.headers['X-Api-Token'] = token;
    }
    opts = _.merge(opts, data);
    opts.headers = new Headers(opts.headers);

    return fetch(process.env.REACT_APP_API_PATH + uri, opts).then(function(response) {
        if (response.status !== 200) {
            if (dispatch) {
                return (response.json()).then(
                    (res) => {
                        dispatch(setAjaxError({ response: res, status: response.status, url: response.url }));
                        return false;
                    }
                );
            }
        } else {
            if (fileDownload) {
                return response.blob();
            } else {
                return response.json();
            }
        }
    });
};

// форматируем телефон вида +7XXXXXXXXXX в +7 (XXX)XXX-XX-XX
export const formatPhone = (phone) => {
    if (phone) {
        return phone.substr(0, 2) + ' (' + phone.substr(2, 3) + ') ' + phone.substr(5, 3) + '-' + phone.substr(8, 2) + '-' +
            phone.substr(10, 2);
    }
    return '';
};

// правильное окончание существительного коего... n
export const declOfNum = (number, titles) => {
    if (number) {
        number = number.toString().substr(-4);
    }
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

// Generate URL-encoded query string
export const httpBuildQuery = (formdata, numericPrefix, argSeparator) => {
    let key;
    let useVal;
    let useKey;
    let i = 0;
    const tmpArr = [];

    if (!argSeparator) {
        argSeparator = '&';
    }
    for (key in formdata) {
        useKey = encodeURIComponent(key);
        useVal = _.isObject(formdata[key]) ? formdata[key] : encodeURIComponent((formdata[key].toString()));
        if (useVal !== '') {
            if (_.isObject(useVal)) {
                if (numericPrefix && !isNaN(key)) {
                    useKey = numericPrefix + i;
                }
                for (const _key in useVal) {
                    tmpArr[i] = useKey + '[' + _key + ']=' + useVal[_key];
                    i++;
                }
            } else {
                if (numericPrefix && !isNaN(key)) {
                    useKey = numericPrefix + i;
                }
                tmpArr[i] = useKey + '=' + useVal;
                i++;
            }
        }
    }
    return tmpArr.join(argSeparator);
};

export const stringifyQuery = (params) => {
    return qs.stringify(params, { arrayFormat: 'indices' });
};

export const parseQueryString = (query) => {
    return qs.parse(query, { arrayLimit: 0 });
};

// форматируем число 7321257 => 7 321 257
export const formatNumber = (price) => {
    return price.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
};

// передаем  определенное количество дней - получаем строку с названием промежутка времени
export const getPeriodTitle = (period) => {
    switch (period) {
    case 7:
        return 'неделю';
        // eslint-disable-next-line default-case-last
    default:
    case 30:
        return 'месяц';
    case 365:
        return 'год';
    }
};

// получаем позицию модального окна в зависимости от размеров окна браузера
export const getBlockPosition = (height, width) => {
    return {
        top: window.innerHeight < height ? 0 + 'px' : (window.innerHeight - height) / 2 + 'px',
        left: (window.innerWidth - width) / 2 + 'px',
        height: height + 'px',
        width: width + 'px'
    };
};

// определяем выход элемента за границы вьюпорта
export const getElScreenOffset = (el, offsetX, offsetY) => {
    if (el) {
        const rect = el.getBoundingClientRect();
        const html = document.documentElement;
        const vWidth = html.clientWidth;
        const vHeight = html.clientHeight;
        return {
            overflowTop: rect.top - offsetY < 0,
            overflowBottom: rect.bottom - offsetY > vHeight,
            overflowRight: rect.right - offsetX > vWidth,
            overflowLeft: rect.left - offsetX < 0

        };
    }
};
// добавляем класс элементу
export const addClass = (el, className) => {
    if (el.classList) {
        el.classList.add(className);
    } else {
        el.className += ' ' + className;
    }
};
// удаляем класс у элемента
export const removeClass = (el, className) => {
    if (el.classList) {
        el.classList.remove(className);
    } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
};
// проверка наличия класса у элемента
export const hasClass = (el, className) => {
    if (el) {
        if (el.classList) { return el.classList.contains(className); } else { return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className); }
    } else {
        return false;
    }
};
export const isEmpty = (mixedVar) => { // Determine whether a variable is empty
    return (mixedVar === '' || mixedVar === 0 || mixedVar === '0' || mixedVar === null || mixedVar === false || ((_.isArray(mixedVar) || _.isObject(mixedVar)) && mixedVar.length === 0));
};
// получаем текст ошибки для вывода на экран
export const getErrorOutput = (error) => {
    switch (error) {
    case 'The nick field is required.':
        return 'Погоняло клиента обязательно';
    case 'The phone field is required.':
        return 'Номер телефона клиента обязателен';
    case 'The phone has already been taken.':
        return 'Данный номер занят другим клиентом';
    case 'The phone must be at least 12 characters.':
        return 'Неполный номер телефона';
    case 'The next serviced at field is required.':
        return 'Дата следующего оказания услуги обязательна';
    case 'The repeats during period field is required.':
        return 'Цикличность приглашений обязательна';
    case 'The service id field is required.':
        return 'Услуга обязательна';
    case 'The average duration field is required.':
        return 'Длительность услуги обязательна';
    case 'The min price field is required.':
        return 'Стоимость услуги обязательна';
    case 'The name field is required when service template id is not present.':
        return 'Название услуги обязательно';
    default:
        return 'Мы от вас такого не ожидали';
    }
};
// транслитерация текста
export const translit = (text) => {
// Символ, на который будут заменяться все спецсимволы
    const space = '-';
    // Берем значение из нужного поля и переводим в нижний регистр
    text = text.toLowerCase();
    // Массив для транслитерации
    const transl = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'sh',
        ъ: space,
        ы: 'y',
        ь: space,
        э: 'e',
        ю: 'yu',
        я: 'ya',
        ' ': space,
        _: space,
        '`': space,
        '~': space,
        '!': space,
        '@': space,
        '#': space,
        $: space,
        '%': space,
        '^': space,
        '&': space,
        '*': space,
        '(': space,
        ')': space,
        '-': space,
        '=': space,
        '+': space,
        '[': space,
        ']': space,
        '\\': space,
        '|': space,
        '/': space,
        '.': space,
        ',': space,
        '{': space,
        '}': space,
        '\'': space,
        '"': space,
        ';': space,
        ':': space,
        '?': space,
        '<': space,
        '>': space,
        '№': space
    };

    let result = '';
    let currentSim = '';

    for (let i = 0; i < text.length; i++) {
        // Если символ найден в массиве то меняем его
        if (transl[text[i]] !== undefined) {
            if (currentSim !== transl[text[i]] || currentSim !== space) {
                result += transl[text[i]];
                currentSim = transl[text[i]];
            }
        } else { // Если нет, то оставляем так как есть
            result += text[i];
            currentSim = text[i];
        }
    }
    result = result.replace(/^-/, '');
    result = result.replace(/-$/, '');
    // Выводим результат
    return result;
};
// Преобразовываем время в нужный формат
export const timeToMoment = (time) => {
    const timeMoment = moment(time);
    moment.locale('ru');
    // moment.utc('+0700');
    return (timeMoment.format('H:mm'));
};
// Преобразовываем дату в нужный формат
export const dateToMoment = (date) => {
    const timeMoment = moment(date);
    moment.locale('ru');
    return (timeMoment.format('DD.MM.YYYY '));
};

export const statusChanger = (event) => {
    const status = event.current_status;
    switch (status) {
    case 'INSUFFICIENT_BALANCE':
        return (<div className={classes.noBalance}>Нет баланса</div>);
    case 'BLOCKED':
        return ('Заблокирован');
    case 'USER_DELIVERED':
        return (<div className={classes.delivered}>Доставлен</div>);
    case 'SENT_TO_QUEUE':
        return (<div className={classes.noBalance}>Отправлено в очередь</div>);
    default:
        return (status);
    }
};

export const statusChangerDevice = (event) => {
    const deviceID = event.device;
    switch (deviceID) {
    case 'x86_64':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.chromePic} />
                <div>{ event.device }</div>
            </div>
        );

    case 'Android SDK built for x86':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.androidPic} />
                <div>{ event.device }</div>
            </div>
        );

    case 'MacIntel':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.applePic} />
                <div>{ event.device }</div>
            </div>
        );

    case 'ONEPLUS A6010':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.androidPic} />
                <div>{ event.device }</div>
            </div>
        );

    case 'ONEPLUS A3010':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.androidPic} />
                <div>{ event.device }</div>
            </div>
        );

    case 'Nexus 4':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.androidPic} />
                <div>{ event.device }</div>
            </div>
        );

    case 'Linux x86_64':
        return (
            <div className={classes.deviceContainer}>
                <div className={classes.chromePic} />
                <div>{ event.device }</div>
            </div>
        );

    default:
        return (deviceID);
    }
};

export const newFilter = (field, value, filters, transformFiltersToSearchValues, updaterFilters, history) => {
    const filterValue = {
        fieldLabel: field.label,
        field: field.name,
        value: value.value
    };
    // eslint-disable-next-line no-empty
    if (_.some(filters, { field: filterValue.field, value: filterValue.value })) {
    } else {
        this.filters.push(filterValue);
        updaterFilters(filters);
        const searchValues = _.merge(this.props.searchValues, transformFiltersToSearchValues(filters));
        history.push(this.props.location.pathname + httpBuildQuery(searchValues, '', '&'));
    }
};

export const generateFields = (statusesAndReasons) => {
    return [
        {
            label: 'send_reason',
            name: 'Причина отправки',
            type: 'select',
            values: _.map(_.get(statusesAndReasons, 'reasons', []), (reason) => {
                return { value: reason, title: reason };
            })
        },
        {
            label: 'current_status',
            name: 'Статус',
            type: 'select',
            values: _.map(_.get(statusesAndReasons, 'statuses', []), (status) => {
                return { value: status, title: status };
            })
        },
        {
            label: 'type',
            name: 'Тип',
            type: 'select',
            values: [{ value: 'sms', title: 'SMS' }, { value: 'push', title: 'PUSH' }]
        },
        {
            label: 'device',
            name: 'Платформа',
            type: 'select',
            values: [{ value: 'device', title: 'device' }, { value: 'push', title: 'push' }]
        },
        {
            label: 'recipient_type',
            name: 'Получатель',
            type: 'select',
            values: [{ value: 'PARTNER', title: 'Партнёр' }, { value: 'CLIENT', title: 'Клиент' }]
        },
        {
            label: 'entity_type',
            name: 'Заказ/резерв',
            type: 'select',
            values: [{ value: 'order', title: 'Заказ' }, { value: 'reserve', title: 'Бронь' }]
        },
        {
            label: 'recipient_contact',
            name: 'Контакт получателя',
            type: 'phone',
            values: [{ value: 'phone', title: 'Телефон' }]
        }
    ];
};
export const checkAdder = (field, value, filters) => {
    const found = _.filter(filters, { fieldLabel: field });
    return (found && _.find(found, { value: value }));
};

export const transformFiltersToSearchValues = (filters) => {
    return _.reduce(filters, (result, filter) => {
        if (result[filter.fieldLabel]) {
            result[filter.fieldLabel] = result[filter.fieldLabel] + ',' + filter.value;
        } else {
            result[filter.fieldLabel] = filter.value;
        }
        return result;
    }, {});
};
