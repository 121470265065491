import React, { Component } from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store/createStore';
import IndexRouter from '../routes';
import { BrowserRouter as Router } from 'react-router-dom';
const store = configureStore({});

class AppContainer extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router> { /* place ConnectedRouter under Provider */ }
                    <IndexRouter store={store} />
                </Router>
            </Provider>
        );
    }
}

export default AppContainer;
